import { currentUser } from "./user";
import { currentUserAccount } from "./account";
import {
  getEntityId,
  getRelId,
  relIsPresent,
  relRefersToEntity,
} from "../apihelper";
import { allResourceType } from "./resourceType";
import "../../lib/groupBy";
import "../../lib/unique";
import * as contractRoleSelectors from "./contractRole";
import * as contractSelectors from "./contract";
import * as clientSelectors from "./client";
import * as accountSelectors from "./account";
import * as storehelper from "../storehelper";
import * as projectResourceSelectors from "./projectResource";
import { ResourceObject, ResourceObjects } from "../../lib/models";

const ResourceTypeValues = allResourceType;

export const allResources = (state: any): ResourceObjects =>
  state.api.resource.allResources;

export const filterResourcesByTypeIds = (
  resources: ResourceObjects,
  resourceTypeIds: string[]
) =>
  resources.filter((c) =>
    resourceTypeIds.includes(getRelId(c, "resourceType") as string)
  );

export const filterByAccounts = (
  resources: ResourceObjects,
  accounts: ResourceObjects
) =>
  storehelper.filterByReferenceToRelatedEntities(
    resources,
    accounts,
    "account"
  );

export const filterByProjectResources = (
  resources: ResourceObjects,
  projectResources: ResourceObjects
) => storehelper.filterByRelatedEntity(resources, projectResources, "resource");

// return all resources from the provided set that are assignable as either via default project resources or are directly assigned
export const filterAssignableResources = (
  resources: ResourceObjects,
  projectResources: ResourceObjects,
  contractRoles: ResourceObjects,
  contracts: ResourceObjects,
  clients: ResourceObjects,
  accounts: ResourceObjects
) => {
  const defaultProjectResources = projectResources
    ? projectResources.filter(projectResourceSelectors.isDefaultProjectResource)
    : [];
  const defaultProjectResourceAccounts = accountSelectors.filterByClients(
    accounts,
    clientSelectors.filterByContracts(
      clients,
      contractSelectors.filterByContractRoles(
        contracts,
        contractRoleSelectors.filterByProjectResources(
          contractRoles,
          defaultProjectResources
        )
      )
    )
  );

  // every resource at an account can be allocated to a default project resource
  const acctResources = filterByAccounts(
    resources,
    defaultProjectResourceAccounts
  );

  // now get the direct assignments
  const userSpecificProjectResources = projectResources
    ? projectResources.filter(
        (pr) => !projectResourceSelectors.isDefaultProjectResource(pr)
      )
    : [];
  const directlyAssignedResources = filterByProjectResources(
    resources,
    userSpecificProjectResources
  );

  return Array.from(new Set([...acctResources, ...directlyAssignedResources]));
};

export const filterByResourceId = (
  allResources: ResourceObjects,
  resourceId: string
) => storehelper.findById(allResources, resourceId);

export const allResourcesByAccount = (
  state: any,
  account: ResourceObject,
  resourceTypes = ResourceTypeValues(state)
) => {
  const resources = allResources(state).filter((c) =>
    relRefersToEntity(c, "account", account)
  );
  return filterResourcesByTypeIds(
    resources,
    resourceTypes.map((rt: ResourceObject) => getEntityId(rt))
  );
};

export const allResourcesForCurrentAccount = (state: any) =>
  allResourcesByAccount(state, currentUserAccount(state));

export const resourceById = (state: any, resourceId: string) =>
  filterByResourceId(allResources(state), resourceId);

export const resourceByUser = (state: any, user: ResourceObject) =>
  user != null &&
  relIsPresent(user, "resource") &&
  resourceById(state, getRelId(user, "resource") as string);

export const resourceForCurrentUser = (state: any) =>
  resourceByUser(state, currentUser(state) as ResourceObject);

export const currentUserResource = (state: any) =>
  state.api.resource.currentUserResource;
