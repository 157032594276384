import React from "react";
import { Checkbox } from "@fluentui/react";
import SingleBaseForm, {
  SingleBaseFormProps,
  SingleBaseFormState,
  SingleFormFieldValues,
} from "./SingleBaseForm";
import DatePicker from "../uifabricextensions/DatePicker";
import ResourcePicker from "../uifabricextensions/ResourcePicker";
import BasePickerSimple from "../uifabricextensions/BasePickerSimple";
import * as apihelper from "../../selectors/apihelper";
import {
  createEmptyModelAttributes,
  createEmptyModelRelationships,
} from "../../lib/emptyModelCreation";
import * as EntityTypes from "../../constants/EntityTypes";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import * as datehelper from "../../lib/date";
import { Label } from "@fluentui/react/lib/Label";
import * as storehelper from "../../selectors/storehelper";
import { ProjectResourceFormFields } from '../../constants/FormFields';
import {Dropdown} from '@fluentui/react/lib/Dropdown';
import CurrentUserContext from '../../contexts/CurrentUserContext';

type ProjectResourceFormProps = SingleBaseFormProps & {
  contractRoles: Array<ResourceObject>;
  projects: Array<ResourceObject>;
  projectResources: Array<ResourceObject>;
  resources: Array<ResourceObject>;
};

type ProjectResourceFormState = SingleBaseFormState;

export default class ProjectResourceForm extends SingleBaseForm<
  ProjectResourceFormProps,
  ProjectResourceFormState
> {
  constructor(props: ProjectResourceFormProps) {
    super(props);
    const { model } = props;
    this.state = {
      fields: SingleBaseForm._initializeFields(
        {
          isDefault: {
            getVal: () =>
              apihelper.getAttr(this.props.model, "isDefaultProjectResource"),
            setVal: () =>
              createEmptyModelAttributes(EntityTypes.PROJECTRESOURCE, {
                isDefaultProjectResource: this.state.fields.isDefault.value,
              }),
            isRequired: false,
          },
          effectiveDate: {
            getVal: () => apihelper.getAttr(this.props.model, "effectiveDate"),
            setVal: () =>
              createEmptyModelAttributes(EntityTypes.PROJECTRESOURCE, {
                effectiveDate: datehelper.toYyyyMmDdHhMmSs(
                  new Date(this.state.fields.effectiveDate.value)
                ),
              }),
            isRequired: true,
          },
          expiryDate: {
            getVal: () => apihelper.getAttr(this.props.model, "expiryDate"),
            setVal: () =>
              createEmptyModelAttributes(EntityTypes.PROJECTRESOURCE, {
                expiryDate: datehelper.toYyyyMmDdHhMmSs(
                  new Date(this.state.fields.expiryDate.value)
                ),
              }),
            isRequired: true,
          },
          projectId: {
            getVal: () => apihelper.getRelId(this.props.model, "project"),
            setVal: () =>
              createEmptyModelRelationships(EntityTypes.PROJECTRESOURCE, {
                project: {
                  id: this.state.fields.projectId.value,
                  type: EntityTypes.PROJECT,
                },
              }),
            isRequired: true,
          },
          resourceId: {
            getVal: () => apihelper.getRelId(this.props.model, "resource"),
            setVal: () =>
              createEmptyModelRelationships(EntityTypes.PROJECTRESOURCE, {
                resource: {
                  id: this.state.fields.resourceId.value,
                  type: EntityTypes.RESOURCE,
                },
              }),
            isRequired: false,
          },
          contractRoleId: {
            getVal: () => apihelper.getRelId(this.props.model, "contractRole"),
            setVal: () =>
              createEmptyModelRelationships(EntityTypes.PROJECTRESOURCE, {
                contractRole: {
                  id: this.state.fields.contractRoleId.value,
                  type: EntityTypes.CONTRACTROLE,
                },
              }),
            isRequired: true,
          },
        },
        model
      ),
    };

    this._onProjectChange = this._onProjectChange.bind(this);
    this._onContractRoleChange = this._onContractRoleChange.bind(this);
    this._onResourceIdChange = this._onResourceIdChange.bind(this);
    this._onEffectiveDateChange = this._onEffectiveDateChange.bind(this);
    this._onExpirationDateChange = this._onExpirationDateChange.bind(this);
    this._onIsDefaultProjectResourceChange =
      this._onIsDefaultProjectResourceChange.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._dateOverlapsWithExistingProjectResource =
      this._dateOverlapsWithExistingProjectResource.bind(this);
    this._endTimeIsBeforeStartTime = this._endTimeIsBeforeStartTime.bind(this);
    this._resourceIsRequired = this._resourceIsRequired.bind(this);
    this._effectiveDateValidation = this._effectiveDateValidation.bind(this);
    this._expiryDateValidation = this._expiryDateValidation.bind(this);
    this._resourceSelectionValidation =
      this._resourceSelectionValidation.bind(this);
    this._contractRoleValidation = this._contractRoleValidation.bind(this);
  }

  _setProjectById(projectId: string) {
    this._updateFieldState(
      "projectId",
      projectId,
      !projectId ? "Project cannot be empty" : undefined
    );
  }

  _onProjectChange(value: ResourceObject | null | undefined) {
    if (value) {
      this._setProjectById(value.id);
    }
  }

  _onContractRoleChange(value: ResourceObject | null | undefined) {
    const { fields } = this.state;
    if (value && value.id) {
      const updatedValues: SingleFormFieldValues = {
        contractRoleId: {
          value: value.id,
          validationMessage: !value.id
            ? "Contract role cannot be empty"
            : undefined,
        },
      };

      const selectedExpiryDateSet =
        fields.expiryDate.value &&
        !fields.expiryDate.value.toString().startsWith("0001");

      const selectedEffectiveDateSet =
        fields.effectiveDate.value &&
        !fields.effectiveDate.value.toString().startsWith("0001");

      if (!selectedExpiryDateSet && !selectedEffectiveDateSet) {
        const selectedContractRole = storehelper.findById(
          this.props.contractRoles,
          value.id
        );
        if (selectedContractRole) {
          updatedValues.effectiveDate = {
            value: apihelper.getAttr(selectedContractRole, "effectiveDate"),
          };
          updatedValues.expiryDate = {
            value: apihelper.getAttr(selectedContractRole, "expiryDate"),
          };
        }
      }
      this._updateFieldState(updatedValues);
    }
  }

  _onResourceIdChange(selectedResources: ResourceObjects) {
    this._updateFieldState(
      "resourceId",
      selectedResources[0] ? apihelper.getEntityId(selectedResources[0]) : null
    );
  }

  _onIsDefaultProjectResourceChange(
    ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    isChecked?: boolean | undefined
  ) {
    this._updateFieldState("isDefault", isChecked);
  }

  _effectiveDateValidation(): string {
    const { effectiveDate } = this.state.fields;
    const { contractRoles, projects } = this.props;
    const errors: string[] = [];

    const selectedProject = projects.find((p) =>
      apihelper.entityHasId(p, this.state.fields.projectId.value)
    );
    if (selectedProject) {
      const contractRolesByProject = contractRoles.filter((cr) =>
        apihelper.relHasId(
          cr,
          "contract",
          apihelper.getRelId(selectedProject, "contract")
        )
      );
      const selectedContractRole = contractRolesByProject.find((cr) =>
        apihelper.entityHasId(cr, this.state.fields.contractRoleId.value)
      );

      if (selectedContractRole) {
        const minDate = selectedContractRole
          ? datehelper.startOfDay(
              new Date(
                apihelper.getAttr(
                  selectedContractRole,
                  "effectiveDate"
                ) as string
              )
            )
          : undefined;

        const maxDate = selectedContractRole
          ? datehelper.endOfDay(
              new Date(
                apihelper.getAttr(selectedContractRole, "expiryDate") as string
              )
            )
          : undefined;

        const effectiveDateAsDate = new Date(effectiveDate.value);

        if (minDate && datehelper.isBefore(effectiveDateAsDate, minDate)) {
          errors.push("Start date is too early");
        }

        if (maxDate && datehelper.isAfter(effectiveDateAsDate, maxDate)) {
          errors.push("Start date is too late");
        }

        if (this._endTimeIsBeforeStartTime()) {
          errors.push("End date is before start date");
        }
      }
    }

    return errors.join(", and ");
  }

  _expiryDateValidation(): string {
    const { expiryDate } = this.state.fields;
    const { contractRoles, projects } = this.props;
    const errors: string[] = [];

    const selectedProject = projects.find((p) =>
      apihelper.entityHasId(p, this.state.fields.projectId.value)
    );

    if (selectedProject) {
      const contractRolesByProject = contractRoles.filter(
        (cr) =>
          selectedProject &&
          apihelper.relHasId(
            cr,
            "contract",
            apihelper.getRelId(selectedProject, "contract")
          )
      );
      const selectedContractRole = contractRolesByProject.find((cr) =>
        apihelper.entityHasId(cr, this.state.fields.contractRoleId.value)
      );

      if (selectedContractRole) {
        const minDate = selectedContractRole
          ? datehelper.startOfDay(
              new Date(
                apihelper.getAttr(
                  selectedContractRole,
                  "effectiveDate"
                ) as string
              )
            )
          : undefined;

        const maxDate = selectedContractRole
          ? datehelper.endOfDay(
              new Date(
                apihelper.getAttr(selectedContractRole, "expiryDate") as string
              )
            )
          : undefined;

        const expiryDateAsDate = new Date(expiryDate.value);

        if (minDate && datehelper.isBefore(expiryDateAsDate, minDate)) {
          errors.push("End date is too early");
        }

        if (maxDate && datehelper.isAfter(expiryDateAsDate, maxDate)) {
          errors.push("End date is too late");
        }

        if (this._endTimeIsBeforeStartTime()) {
          errors.push("End date is before start date");
        }
      }
    }

    return errors.join(", ");
  }

  _resourceSelectionValidation() {
    const { resourceId, isDefault } = this.state.fields;
    const isValidDefaultResource =
      resourceId.value === null && isDefault.value === true;
    const isValidNamedResource =
      resourceId.value !== null && isDefault.value === false;

    if (!(isValidDefaultResource || isValidNamedResource))
      return "Invalid resource configuration";
  }
  _contractRoleValidation() {
    const { contractRoleId } = this.state.fields;
    if (contractRoleId.value === null) return "Contract role required";
  }

  _isFormValid() {
    const { contractRoleId } = this.state.fields;

    const invalidResource = this._resourceSelectionValidation();
    const expiryValidationMessage = this._expiryDateValidation();
    const effectiveValidationMessage = this._effectiveDateValidation();
    const overlapsWithExisting =
      this._dateOverlapsWithExistingProjectResource();

    return (
      !invalidResource &&
      contractRoleId.value !== null &&
      !expiryValidationMessage &&
      !effectiveValidationMessage &&
      !overlapsWithExisting
    );
  }

  _endTimeIsBeforeStartTime() {
    const { effectiveDate, expiryDate } = this.state.fields;
    const effectiveDateAsDate = new Date(effectiveDate.value);
    const expiryDateAsDate = new Date(expiryDate.value);
    return (
      datehelper.isBefore(expiryDateAsDate, effectiveDateAsDate) &&
      !datehelper.isSameDay(expiryDateAsDate, effectiveDateAsDate)
    );
  }

  _dateOverlapsWithExistingProjectResource() {
    const { contractRoleId, resourceId, effectiveDate, expiryDate } =
      this.state.fields;
    const { model, projectResources } = this.props;

    const filterfn = (pr: ResourceObject) => {
      const isCurrentPR = apihelper.entityHasId(
        model,
        apihelper.getEntityId(pr)
      );
      const hasSameContractRole = apihelper.relHasId(
        pr,
        "contractRole",
        contractRoleId.value
      );
      const hasSameResourceId = apihelper.relHasId(
        pr,
        "resource",
        resourceId.value
      );
      return !isCurrentPR && hasSameContractRole && hasSameResourceId;
    };
    const potentialOverlaps = projectResources.filter(filterfn);

    const effectiveDateAsDate = new Date(effectiveDate.value);
    const expiryDateAsDate = new Date(expiryDate.value);

    const hasOverlap = potentialOverlaps.some((existingProjectResource) => {
      const existingEffectiveDateAsDate = new Date(
        apihelper.getAttr(existingProjectResource, "effectiveDate") as string
      );
      const existingExpiryDateAsDate = new Date(
        apihelper.getAttr(existingProjectResource, "expiryDate") as string
      );

      return datehelper.intersectsPeriod(
        existingEffectiveDateAsDate,
        existingExpiryDateAsDate,
        effectiveDateAsDate,
        expiryDateAsDate
      );
    });

    return hasOverlap
      ? "Date interval overlaps with date interval with existing project resource"
      : undefined;
  }

  _resourceIsRequired() {
    return !this.state.fields.isDefault.value;
  }

  _onEffectiveDateChange(value: Date) {
    this._updateFieldState("effectiveDate", value);
  }

  _onExpirationDateChange(value: Date) {
    this._updateFieldState("expiryDate", value);
  }

  _renderButtons() {
    const shouldDisable = !this._isFormValid();
    return super._renderButtons(shouldDisable);
  }

  render() {
    const { model, resources, contractRoles, projects } = this.props;
    const { fields } = this.state;
    const selectedResources = resources.filter((r) =>
      apihelper.entityHasId(r, fields.resourceId.value)
    );
    const selectedProject = projects.find((p) =>
      apihelper.entityHasId(p, fields.projectId.value)
    );

    const contractRolesByProject = contractRoles.filter(
      (cr) =>
        selectedProject &&
        apihelper.relHasId(
          cr,
          "contract",
          apihelper.getRelId(selectedProject, "contract")
        )
    );
    const selectedContractRole = contractRolesByProject.find((cr) =>
      apihelper.entityHasId(cr, fields.contractRoleId.value)
    );
    const selectedEffectiveDate =
      !fields.effectiveDate.value ||
      fields.effectiveDate.value.toString().startsWith("0001")
        ? ""
        : new Date(fields.effectiveDate.value);
    const selectedExpiryDate =
      !fields.expiryDate.value ||
      fields.expiryDate.value.toString().startsWith("0001")
        ? ""
        : new Date(fields.expiryDate.value);

    const minDate = selectedContractRole
      ? datehelper.startOfDay(
          new Date(
            apihelper.getAttr(selectedContractRole, "effectiveDate") as string
          )
        )
      : undefined;
    const maxDate = selectedContractRole
      ? datehelper.endOfDay(
          new Date(
            apihelper.getAttr(selectedContractRole, "expiryDate") as string
          )
        )
      : undefined;

    const resourcePickerDisabled = fields.isDefault.value;

    return (
      <div>
        <h2>{model ? "Edit" : "Create"} Project Resource</h2>
        <br />
        <BasePickerSimple
          label="Project"
          isRequired={!!fields.projectId.isRequired}
          entities={projects}
          selectedEntity={selectedProject}
          onEntitySelected={this._onProjectChange}
        />
        <br />
        <fieldset className="novatime-resource-fieldset">
          <legend>Select resource</legend>
          <CurrentUserContext.Consumer>
            {({ currentUserPermissionSet }) => {
              return currentUserPermissionSet?.hasSelectedAccountRead() &&
                  <>
                    <p>
                      If you are making a default project resource, no resource can be
                      provided.
                    </p>
                    <div>
                      <Label>Default project resource:</Label>
                      <Checkbox
                          onChange={this._onIsDefaultProjectResourceChange}
                          checked={resourcePickerDisabled}
                          disabled={this.state.fields.resourceId.value !== null}
                      />
                    </div>
                    <br/>
                  </>;
            }}
          </CurrentUserContext.Consumer>
          <ResourcePicker
            text="Select resource"
            label="Resource:"
            required={fields.resourceId.isRequired}
            autoComplete="on"
            selectedResources={selectedResources}
            resources={resources.filter((r) =>
              apihelper.getAttr(r, "isActive")
            )}
            onResourceSelected={this._onResourceIdChange}
            itemLimit={1}
            disabled={resourcePickerDisabled}
          />
          <br />
        </fieldset>
        {!this._hasFieldInitialValue(ProjectResourceFormFields.RESOURCE_ID) && this._resourceSelectionValidation() ? (
          <Label style={{ color: "red" }}>
            {this._resourceSelectionValidation()}
          </Label>
        ) : null}
        <BasePickerSimple
          label="Contract role"
          isRequired={!!fields.contractRoleId.isRequired}
          onEntitySelected={this._onContractRoleChange}
          entities={contractRolesByProject}
          selectedEntity={selectedContractRole}
        />
        {!this._hasFieldInitialValue(ProjectResourceFormFields.CONTRACT_ROLE_ID) && this._contractRoleValidation() ? (
          <Label style={{ color: "red" }}>
            {this._contractRoleValidation()}
          </Label>
        ) : null}
        <br />
        <DatePicker
          label="Effective date (inclusive)"
          placeholder={selectedEffectiveDate ? "" : "Select an effective date"}
          isRequired={fields.effectiveDate.isRequired && !selectedEffectiveDate}
          validate={this._effectiveDateValidation}
          value={selectedEffectiveDate}
          onSelectDate={this._onEffectiveDateChange}
          minDate={minDate}
          maxDate={maxDate}
          displayArrows={false}
        />
        {this._dateOverlapsWithExistingProjectResource() ? (
          <Label style={{ color: "red" }}>
            {this._dateOverlapsWithExistingProjectResource()}
          </Label>
        ) : (
          <br />
        )}
        <DatePicker
          label="Expiration date (inclusive)"
          placeholder={selectedExpiryDate ? "" : "Select an expiry date"}
          isRequired={fields.expiryDate.isRequired && !selectedEffectiveDate}
          value={selectedExpiryDate}
          onSelectDate={this._onExpirationDateChange}
          validate={this._expiryDateValidation}
          minDate={minDate}
          maxDate={maxDate}
          displayArrows={false}
        />
        {this._renderButtons()}
        <br />
        {this._renderTimestampsAndId(model)}
      </div>
    );
  }
}
