import { createSelector } from "reselect";
import { getAttr } from "../apihelper";

export const allProjectStatus = state => state.api.projectStatus.allProjectStatus;

export const projectStatusForOngoingProject = createSelector(
    [allProjectStatus],
    (allProjectStatus) => allProjectStatus.find(status => getAttr(status, 'name') === 'Ongoing')
)

export const projectStatusForFinishedProject = createSelector(
    [allProjectStatus],
    (allProjectStatus) => allProjectStatus.find(status => getAttr(status, 'name') === 'Finished')
)
