import * as projectSelectors from './project';
import * as apihelper from '../apihelper';
import * as storehelper from '../storehelper';
import * as datehelper from '../../lib/date';
import { ResourceObject, ResourceObjects } from "../../lib/models";

export const isDefaultProjectResource = (projectResource: ResourceObject) =>
    apihelper.getAttr(projectResource, 'isDefaultProjectResource');

export const filterByResource = (projectResources: ResourceObjects, resource: ResourceObject) => {
    const resourceId = apihelper.getEntityId(resource);
    return projectResources.filter(pr => apihelper.relHasId(pr, 'resource', resourceId))
}

export const filterByIsDefaultProjectResource = (projectResources: ResourceObjects) =>
    projectResources.filter(isDefaultProjectResource);

export const filterByProjects = (
    projectResources: ResourceObjects,
    projects: ResourceObject | ResourceObjects | undefined | null
) =>
    storehelper.filterByReferenceToRelatedEntities(projectResources, projects, 'project');

export const isActiveInPeriod = (
    projectResource: ResourceObject,
    startDate: Date,
    endDate: Date
) => datehelper.isWithinPeriod(
        new Date(apihelper.getAttr(projectResource, 'effectiveDate') as string),
        new Date(apihelper.getAttr(projectResource, 'expiryDate') as string), // outer period
        startDate, endDate // inner period
);

export const filterByActiveInPeriod = (
    projectResources: ResourceObjects,
    startDate: Date,
    endDate: Date
) => projectResources.filter(pr => isActiveInPeriod(pr, startDate, endDate));

export const filterByActiveAtDate = (projectResources: ResourceObjects, date: Date) =>
    filterByActiveInPeriod(projectResources, date, date);

export const splitByDefaultProjectResource = (
    projectResources: ResourceObjects
): {nonDefault: ResourceObjects, default: ResourceObjects} => ({
    default: (projectResources || []).filter(isDefaultProjectResource),
    nonDefault: (projectResources || []).filter(pr => !isDefaultProjectResource(pr))
})

export const filterByResourceAllocation = (
    projectResources: ResourceObjects,
    resource: ResourceObject,
    projects: ResourceObjects
) => {
    const {
        default: defaultProjectResources,
        nonDefault: directlyAllocatedProjectResources
    } = splitByDefaultProjectResource(projectResources);
    const projectsForAccount = projectSelectors.filterByAccountIds(projects, apihelper.getRelId(resource, 'account') as string);
    return filterByResource(directlyAllocatedProjectResources, resource)
        .concat(filterByProjects(defaultProjectResources, projectsForAccount))
}

export const allProjectResources = (state: any) => state.api.projectResource.allProjectResources as ResourceObjects;

export const allProjectResourcesByProject = (state: any, project: ResourceObject) =>
    filterByProjects(allProjectResources(state), project);

export const allProjectResourcesByProjectAndTimestamp = (state: any, project: ResourceObject, date: string | Date) => {
    date = new Date(date);
    return filterByActiveAtDate(allProjectResourcesByProject(state, project), date)
}

export const allProjectResourcesByProjects = (state: any, projects: ResourceObjects) => {
    const projectIds = projects.map(apihelper.getEntityId);
    return allProjectResources(state).filter(pr => projectIds.includes(apihelper.getRelId(pr, 'project') as string));
};

export const projectResourceById = (state: any, projectResourceId: string) =>
    allProjectResources(state).find(pr => apihelper.entityHasId(pr, projectResourceId));
