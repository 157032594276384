import React, {Component} from "react";
import KpiStack from "../../KpiStack";
import {UtilizationAggregate} from "../../../lib/reportAggregators/utilization";
import * as datehelper from "../../../lib/date";
import SorterList from "../../../components/lists/SorterList";
import * as storehelper from "../../../selectors/storehelper";
import * as apihelper from "../../../selectors/apihelper";
import {FontIcon} from "@fluentui/react/lib/Icon";
import {mergeStyles, mergeStyleSets} from "@fluentui/react/lib/Styling";
import {Stack} from "@fluentui/react";
import {ActionButton} from "@fluentui/react/lib/Button";
import {ResourceObject, ResourceObjects} from "../../../lib/models";
import {DefaultCompareFunctions} from "../../lists/sorting";
import {Column} from "../../lists/types";

const iconClass = mergeStyles({
    fontSize: 15,
    height: 15,
    width: 15,
    margin: "0",
});

const iconClassNames = mergeStyleSets({
    attention: [{color: "red"}, iconClass],
    mark: [{color: "orange"}, iconClass],
    allGood: [{color: "green"}, iconClass],
});

const themedSmallStackTokens = {
    childrenGap: "s1",
};

const MessageTypes = {
    Info: "info",
    Attention: "attention",
    Mark: "mark",
};

const messageTypeToIcon = (messageType: string) =>
    messageType === MessageTypes.Info ? "Info" : "Warning";

const messageTypeToClassName = (messageType: string) =>
    messageType === MessageTypes.Info
        ? iconClassNames.allGood
        : messageType === MessageTypes.Attention
            ? iconClassNames.attention
            : messageType === MessageTypes.Mark
                ? iconClassNames.mark
                : undefined;

const baseColumns: Column[] = [
    {
        key: "resourceName",
        name: "Resource",
        fieldName: "resourceName",
        minWidth: 180,
        maxWidth: 180,
        isResizable: true,
        sorting: {
            compareFunction: DefaultCompareFunctions.Lexical((a) => a.resourceName),
        }
    },
    {
        key: "avgHoursPerWeek",
        name: "Hours/week",
        fieldName: "avgHoursPerWeek",
        minWidth: 110,
        maxWidth: 110,
        sorting: {
            compareFunction: DefaultCompareFunctions.Numerical(
                (a) => a.avgHoursPerWeek
            ),
        }
    },
    {
        key: "utilizationFulfillment",
        name: "Util rate",
        fieldName: "utilizationFulfillment",
        minWidth: 90,
        maxWidth: 90,
        sorting: {
            compareFunction: DefaultCompareFunctions.Numerical(
                (a) => a.utilizationFulfillment
            ),
        },
    },
    {
        key: "hoverMessages",
        name: " ",
        fieldName: "hoverMessages",
        minWidth: 40,
        maxWidth: 80,
    },
];

type UtilizationReportProps = {
    reportData: UtilizationAggregate;
    resources: ResourceObjects;
};

type UtilizationReportState = {
    showAllResources: boolean;
};

type HoverMessage = {
    text: string;
    type: string;
};

type ResourceRow = {
    resourceName: string;
    avgHoursPerWeek: number;
    hoverMessages: HoverMessage[];
    utilizationFulfillment: number;
};

export default class UtilizationReport extends Component<
    UtilizationReportProps,
    UtilizationReportState
> {
    constructor(props: UtilizationReportProps) {
        super(props);
        this.state = {showAllResources: false};
        this.toggleShowAllResources = this.toggleShowAllResources.bind(this);
    }

    toggleShowAllResources() {
        this.setState({showAllResources: !this.state.showAllResources});
    }

    render() {
        const {reportData, resources} = this.props;
        const {showAllResources} = this.state;

        const resourceIds = reportData.getResourceIds();
        const numberOfResources = resourceIds.length;
        const startTime = reportData.getStartTime();
        const endTime = reportData.getEndTime();
        const totalPeriodHours = reportData.getPeriodWorkHoursTotal();
        const passedPeriodHours = reportData.getPeriodWorkHoursPassed();
        const periodCompletion =
            totalPeriodHours > 0 ? passedPeriodHours / totalPeriodHours : 0;
        const remainingPeriodHours = reportData.getPeriodWorkHoursRemaining();
        const totalRegisteredHours = reportData.getRegisteredHoursTotal();
        const billableRegisteredHours = reportData.getRegisteredHoursBillable();
        const workToRegisteredFactor =
            passedPeriodHours > 0 ? totalRegisteredHours / passedPeriodHours : 0;

        const totalUtilizationTargetHours =
            reportData.getTargetUtilizationHoursTotal();
        const passedUtilizationTargetHours =
            reportData.getTargetUtilizationHoursPassed();

        const compoundUtilizationTargetForPeriod =
            totalPeriodHours > 0 ? totalUtilizationTargetHours / totalPeriodHours : 0;
        const actualUtilization =
            passedPeriodHours > 0 ? billableRegisteredHours / passedPeriodHours : 0;
        const utilToTarget = Math.floor(
            ((passedUtilizationTargetHours > 0
                    ? billableRegisteredHours / passedUtilizationTargetHours
                    : 0) -
                1) *
            100
        );
        const billableFtes = reportData.getUtilizationTarget();

        const periodKpis = [
            {
                title: "Work hours in period",
                value: totalPeriodHours.toFixed(0),
                description: `Total amount of working hours available from ${datehelper.toYyyyMmDd(
                    startTime
                )} to ${datehelper.toYyyyMmDd(
                    endTime
                )} across ${numberOfResources} resources. Resources without any registrations in the period are not counted.`,
            },
            {
                title: "Work fulfillment",
                value: `${Math.floor(workToRegisteredFactor * 100)}%`,
                description: `The degree to which the contractual amount of work has been registered. To date, ${passedPeriodHours.toFixed(
                    2
                )} of the total ${totalPeriodHours.toFixed(
                    2
                )} has passed and ${totalRegisteredHours.toFixed(
                    2
                )} hours have been registered.`,
            },
        ];
        const utilizationKpis = [
            {
                title: "Compound utilization target",
                value: `${Math.floor(compoundUtilizationTargetForPeriod * 100)}%`,
                description:
                    `The period work hours compared to the sum of billable hours expected. ` +
                    `Sum of billable hours are calculated based on individual resource utilization targets, expecting ${billableFtes.toFixed(
                        2
                    )} FTE across ${numberOfResources} resources ` +
                    `A total of ${totalUtilizationTargetHours.toFixed(
                        2
                    )} billable hours are expected in this ` +
                    `period.`,
            },
            {
                title: "Actual utilization",
                value:
                    `${Math.floor(actualUtilization * 100)}%` +
                    (utilToTarget !== 0 ? ` (${utilToTarget}% off)` : ""),
                description:
                    `Actual utilization of resources to date. ${passedPeriodHours.toFixed(
                        2
                    )} work ` +
                    `hours has passed and ${billableRegisteredHours.toFixed(
                        2
                    )} billable hours have ` +
                    `been registered out of the target ${passedUtilizationTargetHours.toFixed(
                        2
                    )} expected at this date.`,
            },
        ];
        const parkedKpis = [
            {
                title: "Period completion",
                value: `${Math.floor(periodCompletion * 100)}%`,
                description: `Percentage of the work hours available in the period that are already consumed, including today ${datehelper.toYyyyMmDd(
                    new Date()
                )}. ${passedPeriodHours} hours have passed, ${remainingPeriodHours} are still remaining.`,
            },
        ];

        let resourceRows: ResourceRow[] = resourceIds
            .map((resourceId: string) => {
                const resource = storehelper.findById(
                    resources,
                    resourceId
                ) as ResourceObject;
                const registeredHours = reportData.getRegisteredHoursTotal({
                    resourceId,
                });
                const workDaysPassed = reportData.getPeriodWorkDaysPassed({
                    resourceId,
                });
                const avgHoursPerWeek =
                    workDaysPassed > 0 ? (registeredHours / workDaysPassed) * 5 : 0;
                const weekendHours = reportData.getRegisteredHoursInWeekends({
                    resourceId,
                });
                const billableHours = reportData.getRegisteredHoursBillable({
                    resourceId,
                });
                const utilizationTarget = reportData.getUtilizationTarget({
                    resourceId,
                });
                const utilTargetHoursPassed =
                    reportData.getTargetUtilizationHoursPassed({
                        resourceId,
                    });
                const utilizationFulfillment =
                    utilTargetHoursPassed > 0 ? billableHours / utilTargetHoursPassed : 0;
                const hoverMessages: HoverMessage[] = [];
                const isPermanentlyEmployed = apihelper.getAttr(
                    resource,
                    "isPermanentlyEmployed"
                );

                if (utilizationTarget === 0 && utilizationFulfillment < 1) {
                    hoverMessages.push({
                        text: "Configured as not billable",
                        type: MessageTypes.Info,
                    });
                }
                if (weekendHours > 0 && avgHoursPerWeek > 16) {
                    hoverMessages.push({
                        text: `Has worked ${weekendHours} hours during weekends`,
                        type: weekendHours > 8 ? MessageTypes.Attention : MessageTypes.Mark,
                    });
                }
                if (avgHoursPerWeek > 50) {
                    hoverMessages.push({
                        text: `Is working too much`,
                        type: MessageTypes.Attention,
                    });
                }
                if (
                    utilizationTarget === 0 &&
                    billableHours > 0 &&
                    isPermanentlyEmployed
                ) {
                    hoverMessages.push({
                        text: `Has billable hours but no utilization target`,
                        type: MessageTypes.Mark,
                    });
                }
                if (
                    utilizationTarget > 0 &&
                    billableHours === 0 &&
                    utilTargetHoursPassed > 40
                ) {
                    hoverMessages.push({
                        text: `Has utilization target and no billable registrations within a week`,
                        type: MessageTypes.Attention,
                    });
                }

                return {
                    resourceName: apihelper.getAttr(resource, "name"),
                    avgHoursPerWeek,
                    hoverMessages,
                    utilizationFulfillment,
                };
            })
            .sort(
                (a: ResourceRow, b: ResourceRow) =>
                    a.utilizationFulfillment < b.utilizationFulfillment
            );

        if (!showAllResources && resourceRows.length > 3) {
            resourceRows = resourceRows.filter((row) =>
                row.hoverMessages.some(
                    (message) =>
                        message.type === MessageTypes.Attention ||
                        message.type === MessageTypes.Mark
                )
            );
        }
        const didHideSomeResources = resourceRows.length !== resourceIds.length;

        return (
            <>
                <KpiStack kpis={periodKpis}/>
                <KpiStack kpis={utilizationKpis}/>
                <KpiStack kpis={parkedKpis}/>
                <SorterList
                    columns={baseColumns}
                    items={resourceRows}
                    onRenderItemColumn={(item, index, column) => {
                        const fieldContent = item[column?.fieldName as string];
                        switch (column?.key) {
                            case "hoverMessages":
                                return (
                                    <span>
                    {!!fieldContent && fieldContent.length > 0 ? (
                        <Stack horizontal tokens={themedSmallStackTokens}>
                            {fieldContent.map((message: HoverMessage) => (
                                <FontIcon
                                    title={message.text}
                                    iconName={messageTypeToIcon(message.type)}
                                    className={messageTypeToClassName(message.type)}
                                />
                            ))}
                        </Stack>
                    ) : null}
                  </span>
                                );
                            case "avgHoursPerWeek":
                                return <span>{fieldContent.toFixed(2)}</span>;
                            case "utilizationFulfillment":
                                return <span>{(fieldContent * 100).toFixed(0)}%</span>;
                            default:
                                return <span title={fieldContent}>{fieldContent}</span>;
                        }
                    }}
                    selectable={false}
                />
                {didHideSomeResources ? (
                    <div style={{marginLeft: "1em"}}>
                        <ActionButton onClick={this.toggleShowAllResources}>
                            Show all {resourceIds.length} resources
                        </ActionButton>
                    </div>
                ) : null}
            </>
        );
    }
}
