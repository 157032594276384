import React, { useCallback, useMemo, useState } from "react";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import {
  IColumn,
  Icon,
  IDetailsRowProps,
  IRenderFunction,
  Stack,
} from "@fluentui/react";
import HourSet from "../../lib/HourSet";
import { ValidationCollection } from "../../lib/modelValidators";
import * as apihelper from "../../selectors/apihelper";
import * as timeRegUtil from "../../lib/timeRegistration";
import * as datehelper from "../../lib/date";
import { ColumnSorterCollection, GroupDefinition } from "./types";
import { DefaultCompareFunctions, useSortColumn } from "./sorting";
import { Resource } from "../uifabricextensions/Resource";
import * as Icons from "../../constants/Icons";
import ToggleStack, { ToggleItem } from "../ToggleStack";
import GroupList from "./GroupList";

interface TimeRegistrationRow extends Record<string, any> {
  validations: ValidationCollection;
  description: string;
  duration: number;
  date: Date;
  resourceName: string;
  resource: ResourceObject;
  clientName: string;
  projectName: string;
  timeRegistration: ResourceObject;
  isPendingUpload: boolean;
  isUploading: boolean;
  clientProject: string;
}

const timeRegistrationDetailListColumns: IColumn[] = [
  {
    key: "status",
    name: "Status",
    fieldName: "status",
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
  },
  {
    key: "clientProject",
    name: "Project",
    fieldName: "clientProject",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    showSortIconWhenUnsorted: true,
  },
  {
    key: "resourceName",
    name: "Resource",
    fieldName: "resourceName",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    showSortIconWhenUnsorted: true,
  },
  {
    key: "date",
    name: "Date",
    fieldName: "date",
    minWidth: 75,
    maxWidth: 75,
    isResizable: true,
    showSortIconWhenUnsorted: true,
  },
  {
    key: "duration",
    name: "Duration",
    fieldName: "duration",
    minWidth: 75,
    maxWidth: 75,
    isResizable: true,
    showSortIconWhenUnsorted: true,
  },
  {
    key: "description",
    name: "Description",
    fieldName: "description",
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    showSortIconWhenUnsorted: true,
  },
];

const plainColumnRenderer: (
  fieldName: string
) => (timeReg: TimeRegistrationRow) => React.ReactNode = (fieldName: string) =>
  function (timeReg: TimeRegistrationRow): React.ReactNode {
    return <span>{timeReg[fieldName]}</span>;
  };

export type ColumnRenderer = Record<
  string,
  (item: TimeRegistrationRow) => React.ReactNode
>;

const defaultColumnRenderers: ColumnRenderer = {
  resourceName: (item) => <Resource model={item.resource} />,
  date: (item) => <span>{datehelper.toYyyyMmDd(item.date)}</span>,
  status: (item) =>
    Object.keys(item.validations).length > 0 ? (
      <Icon
        className="icon-color-red fixposition icon-column"
        iconName={Icons.ICON_NAME_INVALID_QUEUED}
        title="Time registration is invalid"
      />
    ) : item.isPendingUpload ? (
      <Icon
        className="icon-column"
        iconName={Icons.ICON_NAME_CHANGE_QUEUED}
        title="Changes waiting for upload."
      />
    ) : item.isUploading ? (
      <Icon
        className="icon-column"
        iconName={Icons.ICON_NAME_UPLOAD}
        title="Uploading changes."
      />
    ) : apihelper.isPersistedEntity(item.timeRegistration) ? (
      <Icon
        className="icon-column"
        iconName={Icons.ICON_NAME_SAVED}
        title="Time registration saved."
      />
    ) : (
      <Icon
        iconName={Icons.ICON_NAME_NEW}
        className="icon-column"
        title="Time registration is new and not yet persisted."
      />
    ),
};

const listColumnRenderer: (
  overrides?: ColumnRenderer
) => (
  item?: TimeRegistrationRow,
  index?: number,
  column?: IColumn
) => React.ReactNode = (overrides?: ColumnRenderer) =>
  function (item?: TimeRegistrationRow, index?: number, column?: IColumn) {
    const { fieldName } = column as IColumn;
    const defaultRenderer = plainColumnRenderer(fieldName as string);
    const renderFunc = fieldName
      ? overrides && fieldName in overrides
        ? overrides[fieldName]
        : fieldName in defaultColumnRenderers
        ? defaultColumnRenderers[fieldName]
        : defaultRenderer
      : defaultRenderer;
    return renderFunc(item as TimeRegistrationRow);
  };

const timeRegistrationDetailListSorters: ColumnSorterCollection = {
  clientProject: {
    compareFunction: DefaultCompareFunctions.Lexical(
      (row: TimeRegistrationRow) => row.clientName + row.projectName
    ),
  },
  resourceName: {
    compareFunction: DefaultCompareFunctions.Lexical(
      (row: TimeRegistrationRow) => row.resourceName
    ),
  },
  date: {
    compareFunction: DefaultCompareFunctions.Numerical(
      (row: TimeRegistrationRow) => row.date
    ),
  },
  duration: {
    compareFunction: DefaultCompareFunctions.Numerical(
      (row: TimeRegistrationRow) => row.duration
    ),
  },
  description: {
    compareFunction: DefaultCompareFunctions.Lexical(
      (row: TimeRegistrationRow) => row.description
    ),
  },
};

const toTimeRegistrationRow = (
  timeRegistration: ResourceObject,
  hourSet: HourSet,
  fallbackProjectName: string | undefined
): TimeRegistrationRow => {
  const resource = hourSet.getResourceForTimeRegistration(
    timeRegistration
  ) as ResourceObject;
  const project = hourSet.getProjectForTimeRegistration(
    timeRegistration
  ) as ResourceObject;
  const client = hourSet.getClientForProject(project);
  const clientName = client && (apihelper.getAttr(client, "name") as string) || "";
  const projectName = project && (apihelper.getAttr(project, "name") as string) || fallbackProjectName || "";

  return {
    resource,
    validations:
      hourSet && hourSet.getTimeRegFailedValidations(timeRegistration),
    description:
      (apihelper.getAttr(timeRegistration, "description") as string) || "",
    duration: timeRegUtil.getDuration(timeRegistration),
    date: new Date(apihelper.getAttr(timeRegistration, "startTime") as string),
    resourceName: (apihelper.getAttr(resource, "name") as string) || "",
    clientName,
    projectName,
    clientProject: clientName ? `${clientName} - ${projectName}` : `${projectName}`,
    timeRegistration,
    isPendingUpload: hourSet.stateMerge.isPendingUpload(timeRegistration),
    isUploading: hourSet.stateMerge.isUploading(timeRegistration),
  };
};

const displayHourSumAndCount = (
  groupKey: string,
  rows: TimeRegistrationRow[]
) => {
  const durationSum = rows.reduce((acc, cur) => acc + cur.duration, 0);
  return `${groupKey} - hours: (${durationSum}), entries:`; // number of entries is added by the GroupList per default
};

const groupToggles: ToggleItem<GroupDefinition<TimeRegistrationRow>>[] = [
  {
    displayName: "Client",
    value: {
      getGroupKey: (row: TimeRegistrationRow) => row.clientName,
      getGroupDisplayName: displayHourSumAndCount,
    },
  },
  {
    displayName: "Project",
    value: {
      getGroupKey: (row: TimeRegistrationRow) => row.projectName,
      getGroupDisplayName: displayHourSumAndCount,
    },
  },
  {
    displayName: "Resource",
    value: {
      getGroupKey: (row: TimeRegistrationRow) => row.resourceName,
      getGroupDisplayName: displayHourSumAndCount,
    },
  },
  {
    displayName: "Week",
    value: {
      getGroupKey: (row: TimeRegistrationRow) =>
        row.date.getFullYear() +
        "-" +
        datehelper.toIso8601WeekNumber(row.date) +
        "",
      getGroupDisplayName: displayHourSumAndCount,
    },
  },
];

type TimeRegistrationListProps = {
  timeRegistrations: ResourceObjects;
  columnRenderOverrides?: ColumnRenderer;
  onRenderItem?: IRenderFunction<IDetailsRowProps>;
  selectedTimeRegistration?: ResourceObject | null;
  onSelect?: (selection: ResourceObjects) => void;
  hourSet: HourSet;
  enableGrouping?: boolean;
  omitStatusColumn?: boolean;
  fallbackProjectName?: string;
};

export default function TimeRegistrationList({
  timeRegistrations,
  hourSet,
  columnRenderOverrides,
  onSelect,
  selectedTimeRegistration,
  enableGrouping,
  omitStatusColumn,
  fallbackProjectName,
}: TimeRegistrationListProps) {
  // sortedBy: "date" | "resource" | "project" | "duration" | "description";
  const [enabledGroups, setEnabledGroups] = useState<
    GroupDefinition<TimeRegistrationRow>[]
  >([]);
  const [sortCmd, onToggleColumn] = useSortColumn(
    timeRegistrationDetailListSorters
  );

  const onColumnClick = useCallback(
    (_: React.MouseEvent<HTMLElement>, column: IColumn) =>
      onToggleColumn(column.key),
    [onToggleColumn]
  );

  const columns = useMemo(
    () =>
      timeRegistrationDetailListColumns
        .filter(
          (baseColumn) => !omitStatusColumn || baseColumn.fieldName !== "status"
        )
        .map((baseColumn) => Object.assign({}, baseColumn, { onColumnClick })),
    [timeRegistrationDetailListColumns, onColumnClick, omitStatusColumn]
  );

  const items = useMemo(
    () => timeRegistrations.map((tReg) => toTimeRegistrationRow(tReg, hourSet, fallbackProjectName)),
    [timeRegistrations, hourSet]
  );

  const onRenderItemColumn = useCallback(
    listColumnRenderer(columnRenderOverrides),
    [columnRenderOverrides]
  );

  const _onSelect = (selectedItems: TimeRegistrationRow[]) =>
    onSelect && onSelect(selectedItems.map((item) => item.timeRegistration));

  const isItemSelected = useCallback(
    (item: TimeRegistrationRow) =>
      item.timeRegistration === selectedTimeRegistration,
    [selectedTimeRegistration]
  );

  return (
    <Stack>
      {enableGrouping ? (
        <Stack.Item>
          <ToggleStack toggles={groupToggles} onChange={setEnabledGroups} />
        </Stack.Item>
      ) : null}
      <Stack.Item>
        <GroupList
          sortCommand={sortCmd}
          groupDefinitions={enabledGroups}
          items={items}
          columns={columns}
          onRenderItemColumn={onRenderItemColumn}
          onSelect={_onSelect}
          isItemSelected={isItemSelected}
        />
      </Stack.Item>
    </Stack>
  );
}
