import { createSelector } from 'reselect';
import * as apihelper from '../apihelper';

export const allResourceType = state => state.api.resourceType.allResourceType;

export const resourceTypeNameById = (state, id) => apihelper.getAttr(allResourceType(state).find(rt => apihelper.getEntityId(rt) === id), 'name');

export const employeeResourceType = createSelector(
    [allResourceType],
    (resourceTypes) => resourceTypes.find(rt => apihelper.getAttr(rt, 'name') === "Employee")
)
