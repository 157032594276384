import React, {Component} from "react";
import KpiStack from "../../KpiStack";
import {AccountForm, BillabilityAggregate,} from "../../../lib/reportAggregators/billability";
import * as Currency from "../../../constants/Currency";
import SorterList from "../../../components/lists/SorterList";
import {ActionButton} from "@fluentui/react/lib/Button";
import * as storehelper from "../../../selectors/storehelper";
import * as apihelper from "../../../selectors/apihelper";
import {ResourceObjects} from "../../../lib/models";
import {Column} from "../../lists/types";
import {DefaultCompareFunctions} from "../../lists/sorting";

const companyListDefaultLimit = 3;

const baseColumns: Column[] = [
  {
    key: "clientName",
    name: "Client",
    fieldName: "clientName",
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    sorting:  {
      compareFunction: DefaultCompareFunctions.Lexical((a) => a.clientName),
    }
  },
  {
    key: "billableHours",
    name: "Billable",
    fieldName: "billableHours",
    minWidth: 60,
    maxWidth: 60,
    sorting: {
      compareFunction: DefaultCompareFunctions.Numerical((a) => a.billableHours),
    }
  },
  {
    key: "nonBillableHours",
    name: "Non-billable",
    fieldName: "nonBillableHours",
    minWidth: 80,
    maxWidth: 80,
    sorting: {
      compareFunction: DefaultCompareFunctions.Numerical(
          (a) => a.nonBillableHours
      ),
    }
  },
  {
    key: "meanRate",
    name: "Mean rate",
    fieldName: "meanRate",
    minWidth: 70,
    maxWidth: 70,
    sorting: {
      compareFunction: DefaultCompareFunctions.Numerical((a) => a.meanRate),
    }
  },
  {
    key: "share",
    name: "Share",
    fieldName: "share",
    minWidth: 70,
    maxWidth: 70,
    sorting: {
      compareFunction: DefaultCompareFunctions.Numerical((a) => a.share),
    }
  },
];

type BillabilityReportProps = {
  reportData: BillabilityAggregate;
  clients: ResourceObjects;
};

type BillabilityReportState = {
  showAllCompanies: boolean;
};

type ClientBillability = {
  clientName: string;
  billableHours: number;
  nonBillableHours: number;
  meanRate: number;
  dkkRevenue: number;
  share: number;
  currencyCode: string;
};

export default class BillabilityReport extends Component<
  BillabilityReportProps,
  BillabilityReportState
> {
  constructor(props: BillabilityReportProps) {
    super(props);
    this.state = {
      showAllCompanies: false,
    };
    this.toggleShowAllCompanies = this.toggleShowAllCompanies.bind(this);
  }

  toggleShowAllCompanies() {
    this.setState({ showAllCompanies: !this.state.showAllCompanies });
  }

  render() {
    const { reportData, clients } = this.props;
    const { showAllCompanies } = this.state;

    const meanRates: Record<string, number> = Object.values(Currency).reduce(
      (acc, currencyCode) => {
        const revenue = reportData.getRevenue({
          currencyCode,
          accountForm: AccountForm.Billable,
        });
        const hours = reportData.getHours({
          currencyCode,
          accountForm: AccountForm.Billable,
        });
        const meanRate = hours > 0 ? revenue / hours : 0;
        acc[currencyCode] = meanRate;
        return acc;
      },
      {} as Record<string, number>
    );

    const kpis = Object.keys(meanRates)
      .filter((currencyCode) => meanRates[currencyCode] > 0)
      .map((currencyCode) => ({
        title: `Mean rate for billable`,
        value: `${meanRates[currencyCode].toFixed(0)} ${currencyCode}/h`,
        description: `All revenue divided by all hours rated with this currency`,
      }));

    const totalBillableHours = reportData.getHours({
      accountForm: AccountForm.Billable,
    });
    let clientBillability: ClientBillability[] = reportData
      .getClientIds()
      .map((clientId: string) => {
        const client = storehelper.findById(clients, clientId);
        const billableHours = reportData.getHours({
          clientId,
          accountForm: AccountForm.Billable,
        });
        const nonBillableHours = reportData.getHours({
          clientId,
          accountForm: AccountForm.NonBillable,
        });
        const dkkRevenue = reportData.getRevenue({
          clientId,
          accountForm: AccountForm.Billable,
          currencyCode: Currency.CURRENCY_CODE_DKK,
        });

        return {
          clientName: client ? apihelper.getAttr(client, "name") : "",
          billableHours,
          nonBillableHours,
          meanRate:
            billableHours > 0 ? (dkkRevenue / billableHours).toFixed(0) : 0,
          dkkRevenue,
          share:
            totalBillableHours > 0 ? billableHours / totalBillableHours : 0,
          currencyCode: Currency.CURRENCY_CODE_DKK,
        };
      });

    clientBillability.sort(
      (a: ClientBillability, b: ClientBillability) =>
        b.billableHours - a.billableHours
    );
    const shouldHideSomeClients =
      !showAllCompanies && clientBillability.length > companyListDefaultLimit;
    const countOfClients = clientBillability.length;
    if (shouldHideSomeClients) {
      clientBillability = clientBillability.slice(0, companyListDefaultLimit);
    }

    return (
      <>
        <KpiStack kpis={kpis} />
        <SorterList
          columns={baseColumns}
          items={clientBillability}
          onRenderItemColumn={(item, index, column) => {
            const fieldContent = item[column?.fieldName as string];
            switch (column?.key) {
              case "billableHours":
              case "nonBillableHours":
                return <span>{fieldContent.toFixed(2)}</span>;
              case "meanRate":
                return (
                  <span
                    title={`${item.dkkRevenue.toLocaleString()} ${
                      item.currencyCode
                    } divided by ${item.billableHours} hours`}
                  >
                    {fieldContent} {item.currencyCode}/h
                  </span>
                );
              case "share":
                return <span>{Math.floor(fieldContent * 100)}%</span>;
              default:
                return <span title={fieldContent}>{fieldContent}</span>;
            }
          }}
          selectable={false}
        />
        {shouldHideSomeClients ? (
          <div style={{ marginLeft: "1em" }}>
            <ActionButton onClick={this.toggleShowAllCompanies}>
              Show all {countOfClients} clients
            </ActionButton>
          </div>
        ) : null}
      </>
    );
  }
}
