import {put, select, take, takeLatest} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import * as resourceManagementSelectors from '../../../selectors/pages/resourceManagement';
import moment from 'moment/moment';
import {getAllTimeRegistrationSummaries} from '../../../actions/api/timeRegistrationSummary';


function* doLoadRegistrationSummaries() {
    const st: Date | undefined = yield select(resourceManagementSelectors.startTime);
    const et: Date | undefined = yield select(resourceManagementSelectors.endTime);
    const startTime = moment(st).subtract(1, 'd').toDate();
    const endTime = moment(et).add(1, 'd').toDate();

    yield put(getAllTimeRegistrationSummaries({ startTime, endTime }))

    const recognizeAction = (action: { type: string; }) =>
      (action.type === types.API_TIMEREGISTRATIONSUMMARY_GET_ALL_SUCCESS ||
            action.type === types.API_TIMEREGISTRATIONSUMMARY_GET_ALL_ERROR ||
            action.type === types.API_CALL_CANCELLED)

    yield take(recognizeAction);
}

export default function* loadRegistrationSummaries() {
    yield takeLatest(types.APP_PAGE_RESOURCEMANAGEMENT_LOAD_REGISTRATIONSUMMARIES, doLoadRegistrationSummaries);
}
