import { entityHasId } from "../apihelper";
import * as storehelper from "../storehelper";
import * as apihelper from "../apihelper";
import * as datehelper from "../../lib/date";
import { ResourceObjects } from "../../lib/models";

export const allContractRoles = (state: any) =>
  state.api.contractRole.allContractRoles as ResourceObjects;

export const contractRoleById = (state: any, contractRoleId: string) =>
  allContractRoles(state).find((cr) => entityHasId(cr, contractRoleId));

export const filterByActiveInPeriod = (
  contractRoles: ResourceObjects,
  startDate: Date,
  endDate: Date
) =>
  contractRoles.filter((cr) =>
    datehelper.isWithinPeriod(
      new Date(apihelper.getAttr(cr, "effectiveDate") as string),
      new Date(apihelper.getAttr(cr, "expiryDate") as string), // outer period
      startDate,
      endDate // inner period
    )
  );

export const filterByProjectResources = (
  contractRoles: ResourceObjects,
  projectResources: ResourceObjects
) =>
  storehelper.filterByRelatedEntity(
    contractRoles,
    projectResources,
    "contractRole"
  );

export const filterByTimeRegistrations = (
  contractRoles: ResourceObjects,
  timeRegistrations: ResourceObjects
) =>
  storehelper.filterByRelatedEntity(
    contractRoles,
    timeRegistrations,
    "contractRole"
  );
