import * as SagaEffects  from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {getAllClients} from '../../../actions/api/client';
import {selectedClient, selectedClientId} from '../../../selectors/pages/projectsAndRoles';
import {selectClient} from '../../../actions/pages/projectsAndRoles';
import * as appDomainSelectors from '../../../selectors/app/domain';
import * as sagahelpers from '../../../sagas/helpers'
import {currentUserPermissionSet} from '../../../selectors/reducers/permission';
import {allProjectsByClient} from '../../../selectors/reducers/project';
import {ResourceObjects} from '../../../lib/models';

function* doLoadClients(): any {
    // fetch clients
    const selectedAccount = yield(SagaEffects.select(appDomainSelectors.selectedAccount));
    const response = yield sagahelpers.callAndAwait(getAllClients(selectedAccount));

    // select client if none selected
    if (response.success) {
        const permissions = yield  SagaEffects.select(currentUserPermissionSet)
        const clientId = yield SagaEffects.select(selectedClientId);
        const clientSelected = yield SagaEffects.select(selectedClient);
        const projectsOfSelectedClient: ResourceObjects = yield SagaEffects.select(state => allProjectsByClient(state, clientSelected))
        if (!clientId || !projectsOfSelectedClient.some(project => permissions.hasProjectRead(project))) {
            const clients = yield SagaEffects.select(appDomainSelectors.allClientsForSelectedAccount);
            for (let i = 0; i < clients.length; i++) {
                const projectsOfClient: ResourceObjects = yield SagaEffects.select(state => allProjectsByClient(state, clients[i]))
                if (projectsOfClient.some(project => permissions.hasProjectRead(project))) {
                    yield SagaEffects.put(selectClient(clients[i]));
                    break;
                }
            }
        }
    }
}

export default function* loadClients() {
    yield SagaEffects.takeLatest(types.APP_PAGE_PROJECTS_AND_ROLES_LOAD_CLIENTS, doLoadClients);
}
