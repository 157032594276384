import type { JsonApiResponse, ResourceObjects } from '../lib/models';

export const apiResponse = (action: any) => action.payload?.requestData

export const apiResponseData = (action: any) => apiResponse(action).data;

export const apiResponseIncluded = (action: any): undefined | ResourceObjects => apiResponse(action).included;

export const apiInitAction =  (action: any) => action.payload?.initAction;

export const apiRequestParameter = (action: any, parameterName: string) => action.payload?.initAction.payload.parameters[parameterName];

export const apiSentModel = (action: any) => apiInitAction(action)?.payload?.model;

export const apiSentModelId = (action: any) => apiSentModel(action)?.id;

export const apiResponseErrors = (action: any) => (apiResponse(action) as JsonApiResponse)?.errors;

export const getTransactionId = (action: any) => action.payload?.transactionId;

export const getStartTime = (action: any) => action.payload?.startTime;

export const apiResponseIncludedOfType = (action: any, entityType: string): ResourceObjects => {
    const includes = apiResponseIncluded(action);
    return includes ? includes.filter((r) => r.type === entityType) : []
}
