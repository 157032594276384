import BaseConnector from './Base';

export default class TimeRegistrationSummaryConnector extends BaseConnector {
    getAll(
        {
            resourceIds,
            startTime,
            endTime,
        }: {
            resourceIds?: string[] | null;
            startTime?: Date;
            endTime?: Date;
        } = {},
        includes?: string[]
    ) {
        const q: string[] = [];
        if (resourceIds && resourceIds.length > 0) {
            q.push(`resource.id in (${resourceIds.join(",")})`);
        }
        if (startTime) {
            q.push("startTime gt " + startTime.toISOString());
        }
        if (endTime) {
            q.push("endTime lt " + endTime.toISOString());
        }
        const qs = q.join(" and ");

        includes = includes || [];
        const is = includes.join(",");

        const qp: string[] = [];
        is && qp.push("includes=" + is);
        qs && qp.push("filter=" + qs);

        return this.authFetch(
            this.baseApiUrl + "TimeRegistrations/summary/?" + qp.join("&")
        );
    }
}
