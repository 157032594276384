import * as apihelper from '../apihelper';
import * as storehelper from '../../selectors/storehelper';
import { ResourceObjects } from "../../lib/models";

export const findPermissionTypeById = (permissionTypes: ResourceObjects, id: string) =>
  storehelper.findById(permissionTypes, id);

export const findPermissionTypeByName = (permissionTypes: ResourceObjects, name: string) =>
  permissionTypes.find(pt => apihelper.getAttr(pt, 'name') === name);

export const allPermissionTypes = (state: any) => state.api.permissiontypes.allPermissionTypes as ResourceObjects;
