import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { bindActionCreators } from "redux";
import * as apihelper from "../../selectors/apihelper";
import BusinessCalendar from "../../lib/businessCalendar";
import { createEmptyModel } from "../../lib/emptyModelCreation";
import * as Icons from "../../constants/Icons";
import { TextField } from "@fluentui/react/lib/TextField";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { CommandBar } from "@fluentui/react/lib/CommandBar";
import { Label } from "@fluentui/react/lib/Label";
import * as EntityTypes from "../../constants/EntityTypes";
import DetailsListBasic from "../../components/lists/DetailsListBasic";
import Column from "../../components/grid/Column";
import Row from "../../components/grid/Row";
import Grid from "../../components/grid/Grid";
import { User } from "../../components/uifabricextensions/User";
import AbsencePeriodForm from "../../components/forms/AbsencePeriodForm";
import DeleteForm from "../../components/forms/DeleteForm";
import AbsencePeriodCommentForm from "../../components/forms/AbsencePeriodCommentForm";
import ResourcePicker from "../../components/uifabricextensions/ResourcePicker";
import "../../lib/unique";
import * as pageActions from "../../actions/pages/absencePeriods";
import { isLoggedIn } from "../../selectors/reducers/auth";
import * as reducerUserSelectors from "../../selectors/reducers/user";
import * as reducerResourceSelectors from "../../selectors/reducers/resource";
import * as reducerAccountSelectors from "../../selectors/reducers/account";
import * as localeSelectors from "../../selectors/reducers/locale";
import * as absencePeriodStatusSelectors from "../../selectors/reducers/absencePeriodStatus";
import * as permissionSelectors from "../../selectors/reducers/permission";
import * as AbsencePeriodGroupStatus from "../../constants/AbsencePeriodGroupStatus";
import "../../lib/groupBy";
import { Resource } from "../../components/uifabricextensions/Resource";
import * as pageSelectors from "../../selectors/pages/absencePeriods";
import * as editorActions from "../../actions/EntityEditor";
import * as absencePeriodTypeSelectors from "../../selectors/reducers/absencePeriodType";
import * as appDomainSelectors from "../../selectors/app/domain";
import * as reducerAbsencePeriodStatusSelectors from "../../selectors/reducers/absencePeriodStatus";
import * as reducerAbsencePeriodTypeSelectors from "../../selectors/reducers/absencePeriodType";
import { SpinButton } from "@fluentui/react/lib/SpinButton";
import * as datehelper from "../../lib/date";

let absencePeriodListOrder = [
  {
    key: AbsencePeriodGroupStatus.PENDING.name,
    name: "Pending requests",
    isCollapsed: false,
  },
  {
    key: AbsencePeriodGroupStatus.APPROVED_UPCOMING.name,
    name: "Approved (upcoming)",
  },
  {
    key: AbsencePeriodGroupStatus.APPROVED_ACTIVE.name,
    name: "Approved requests (active)",
    isCollapsed: true,
  },
  {
    key: AbsencePeriodGroupStatus.APPROVED_ARCHIVED.name,
    name: "Approved requests (archived)",
    isCollapsed: true,
  },
  {
    key: AbsencePeriodGroupStatus.DENIED.name,
    name: "Denied requests",
    isCollapsed: true,
  },
];

const calendar = new BusinessCalendar();

class AbsencePeriods extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingOpen: true,
      approvedActive: false,
      approvedArchived: false,
      denied: false,
    };

    this._iconPropsCreate = { iconName: Icons.ICON_NAME_NEW };
    this._absencePeriodStatusToText =
      this._absencePeriodStatusToText.bind(this);
    this._absencePeriodTypeToText = this._absencePeriodTypeToText.bind(this);
    this._onCreateAbsencePeriod = this._onCreateAbsencePeriod.bind(this);
    this._onSelectAbsencePeriod = this._onSelectAbsencePeriod.bind(this);
    this._onEditAbsencePeriod = this._onEditAbsencePeriod.bind(this);
    this._onRemoveAbsencePeriod = this._onRemoveAbsencePeriod.bind(this);
    this._onEditAbsencePeriodComment =
      this._onEditAbsencePeriodComment.bind(this);
    this._onSelectResource = this._onSelectResource.bind(this);
    this._onGroupCollapseClicked = this._onGroupCollapseClicked.bind(this);
    this._onVacationYearChange = this._onVacationYearChange.bind(this);
  }

  componentDidMount() {
    this.props.actions.pageOpened();
  }

  _onSelectResource(selectedResources) {
    console.log(selectedResources[0]);
    this.props.actions.resourceSelected(selectedResources[0]);
  }

  _onGroupCollapseClicked(item) {
    let group = absencePeriodListOrder.find((grp) => grp.name === item.name);
    group.isCollapsed = !group.isCollapsed;
  }

  _onSelectAbsencePeriod(items) {
    this.props.actions.selectAbsencePeriod(
      items.length === 1 ? items[0].id : null
    );
  }

  _onEditAbsencePeriodComment(absencePeriod) {
    this.props.actions.loadComments(apihelper.getEntityId(absencePeriod));
    this.props.actions.startEdit(
      createEmptyModel(
        EntityTypes.ABSENCEPERIODCOMMENT,
        {
          addedDate: new Date(),
        },
        {
          sentByUser: this.props.currentUser,
          absencePeriod: absencePeriod,
        }
      ),
      AbsencePeriodCommentForm
    );
  }

  _onEditAbsencePeriod() {
    this.props.actions.startEdit(
      this.props.selectedAbsencePeriod,
      AbsencePeriodForm
    );
  }

  _onCreateAbsencePeriod() {
    let { pendingAbsencePeriodStatus, vacationAbsencePeriodType } = this.props;

    this.props.actions.startEdit(
      createEmptyModel(
        EntityTypes.ABSENCEPERIOD,
        {
          periodEnd: new Date(),
          periodStart: new Date(),
        },
        {
          resource: this.props.selectedResource
            ? this.props.selectedResource
            : this.props.currentUserResource,
          absencePeriodStatus: pendingAbsencePeriodStatus,
          absencePeriodType: vacationAbsencePeriodType,
        }
      ),
      AbsencePeriodForm
    );
  }

  _onRemoveAbsencePeriod() {
    this.props.actions.startEdit(this.props.selectedAbsencePeriod, DeleteForm);
  }

  _absencePeriodStatusToText(statusId) {
    let { absencePeriodStatuses } = this.props;
    let absencePeriodStatusName = apihelper.getAttr(
      absencePeriodStatuses.find((aps) => apihelper.entityHasId(aps, statusId)),
      "name"
    );
    return statusId ? absencePeriodStatusName : undefined;
  }

  _absencePeriodTypeToText(typeId) {
    let { absencePeriodTypes } = this.props;
    let absencePeriodTypeName = apihelper.getAttr(
      absencePeriodTypes.find((apt) => apihelper.entityHasId(apt, typeId)),
      "name"
    );
    return typeId ? absencePeriodTypeName : undefined;
  }

  _onVacationYearChange(event, newValue) {
    this.props.actions.setSelectedYear(newValue - 0);
  }

  render() {
    let {
      selectedAbsencePeriod,
      selectedAbsencePeriodId,
      currentUserResource,
      currentUserPermissionSet,
      allResourcesForCurrentAccount,
      selectedResource,
      selectedResourceRemainingVacation,
      selectedResourceAbsencePeriodsGrouped,
      selectedResourceApprovedAbsencePeriod,
      vacationHoursGranted,
      usersForAbsencePeriods,
      resourcesForAbsencePeriods,
      locales,
      usersForCurrentAccount,
      selectedYear,
      vacationYearStartDate,
      vacationYearEndDate,
    } = this.props;

    let users = usersForCurrentAccount;
    let selectedResources = selectedResource ? [selectedResource] : [];

    let groups = [];
    let idx = 0;
    let items = [];
    absencePeriodListOrder.forEach((grpSpec) => {
      let vacsInGrp = selectedResourceAbsencePeriodsGrouped[grpSpec.key];
      if (vacsInGrp && vacsInGrp.length) {
        groups.push({
          startIndex: idx,
          count: vacsInGrp.length,
          level: 0,
          children: [],
          name: grpSpec.name,
          isCollapsed: grpSpec.isCollapsed,
        });
        idx += vacsInGrp.length;
        items = items.concat(vacsInGrp);
      }
    });

    let detailsListItems = items.map((ap) => {
      let resource = resourcesForAbsencePeriods.find((r) =>
        apihelper.relRefersToEntity(ap, "resource", r)
      );
      let locale =
        resource && apihelper.relHasReference(resource, "employedAtLocale")
          ? apihelper.getAttr(
              locales.find((l) =>
                apihelper.relRefersToEntity(resource, "employedAtLocale", l)
              ),
              "name"
            )
          : "dk";
      return {
        id: apihelper.getEntityId(ap),
        periodStart: apihelper.getAttr(ap, "periodStart").startsWith("0001")
          ? undefined
          : moment(apihelper.getAttr(ap, "periodStart")).format("YYYY-MM-DD"),
        periodEnd: apihelper.getAttr(ap, "periodEnd").startsWith("0001")
          ? undefined
          : moment(apihelper.getAttr(ap, "periodEnd")).format("YYYY-MM-DD"),
        status: apihelper.getRelId(ap, "absencePeriodStatus"),
        type: apihelper.getRelId(ap, "absencePeriodType"),
        approvedBy: usersForAbsencePeriods.find((u) =>
          apihelper.relRefersToEntity(ap, "approvedByUser", u)
        ),
        requestedBy: usersForAbsencePeriods.find((u) =>
          apihelper.relRefersToEntity(ap, "requestedByUser", u)
        ),
        workdays: calendar.getNumberOfBusinessDaysBetween(
          new Date(apihelper.getAttr(ap, "periodStart")),
          new Date(apihelper.getAttr(ap, "periodEnd")),
          locale,
          true
        ),
        absencePeriod: ap,
        resource,
      };
    });

    let remainingAbsencePeriod = selectedResourceRemainingVacation;
    let approvedAbsencePeriod = selectedResourceApprovedAbsencePeriod;
    let remainingAbsencePeriodMinusApproved = Math.max(
      0,
      remainingAbsencePeriod - approvedAbsencePeriod
    );
    let isEligibleForAbsenceRegistration = !!(
      currentUserResource &&
      apihelper.getAttr(currentUserResource, "eligibleForAbsenceRegistration")
    );

    let absencePeriodDaysThisYear =
      selectedResource && apihelper.getAttr(selectedResource, "vacationHours")
        ? vacationHoursGranted / 8
        : "Specify eligible resource";
    let remainingAbsencePeriodDays =
      selectedResource &&
      apihelper.getAttr(selectedResource, "vacationYearStart")
        ? selectedResourceRemainingVacation / 8
        : "Specify eligible resource";
    let remainingAbsencePeriodMinusApprovedDays =
      selectedResource &&
      apihelper.getAttr(selectedResource, "vacationYearStart")
        ? remainingAbsencePeriodMinusApproved
          ? remainingAbsencePeriodMinusApproved / 8
          : "0"
        : "Specify eligible resource";

    return (
      <div>
        {isEligibleForAbsenceRegistration ||
        currentUserPermissionSet.hasSelectedAccountRead() ? (
          <div className="novatime-container">
            <h2>Absence Requests</h2>
            <Grid>
              <Row>
                <Column sm={12} md={6} lg={3}>
                  <Label>Select resource</Label>
                  <ResourcePicker
                    resources={allResourcesForCurrentAccount}
                    users={users}
                    selectedResources={selectedResources}
                    onResourceSelected={this._onSelectResource}
                    itemLimit={1}
                    disabled={
                      !currentUserPermissionSet.hasSelectedAccountRead()
                    }
                  />
                </Column>
                <Column sm={12} md={6} lg={1}>
                  <Label>Vacation year</Label>
                  <SpinButton
                    value={selectedYear}
                    onChange={this._onVacationYearChange}
                    min={2014}
                    step={1}
                    title={`Vacation year start: ${
                      vacationYearStartDate &&
                      datehelper.toYyyyMmDd(vacationYearStartDate)
                    }, vacation year end: ${
                      vacationYearEndDate &&
                      datehelper.toYyyyMmDd(vacationYearEndDate)
                    }`}
                    incrementButtonAriaLabel="Increase value by 1"
                    decrementButtonAriaLabel="Decrease value by 1"
                  />
                </Column>
                <Column sm={12} md={6} lg={3}>
                  <TextField
                    label="Total vacation days in this vacation year"
                    value={absencePeriodDaysThisYear}
                    readOnly={true}
                  />
                </Column>
                <Column sm={12} md={6} lg={2}>
                  <TextField
                    label="Total remaining vacation days"
                    value={remainingAbsencePeriodDays}
                    readOnly={true}
                  />
                </Column>
                <Column sm={12} md={6} lg={3}>
                  <TextField
                    label="Remaining vacation days without approved vacation"
                    value={remainingAbsencePeriodMinusApprovedDays}
                    readOnly={true}
                  />
                </Column>
              </Row>
            </Grid>
            <br />
            <CommandBar
              items={[
                {
                  key: "new-entity",
                  name: "New",
                  iconProps: { iconName: Icons.ICON_NAME_NEW },
                  className: "ms-CommandBarItem",
                  onClick: this._onCreateAbsencePeriod,
                  disabled: false,
                },
                {
                  key: "edit-entity",
                  name: "Edit",
                  iconProps: { iconName: Icons.ICON_NAME_EDIT },
                  className: "ms-CommandBarItem",
                  onClick: this._onEditAbsencePeriod,
                  disabled: !selectedAbsencePeriod,
                },
                {
                  key: "remove-entity",
                  name: "Remove",
                  iconProps: { iconName: "Remove" },
                  className: "ms-CommandBarItem",
                  onClick: this._onRemoveAbsencePeriod,
                  disabled: !selectedAbsencePeriod,
                },
              ]}
            />
            <br />
            <DetailsListBasic
              columns={[
                {
                  key: "v-resource",
                  name: "Resource",
                  fieldName: "resource",
                  minWidth: 50,
                  maxWidth: 125,
                  isResizable: true,
                },
                {
                  key: "v-start",
                  name: "Absence start",
                  fieldName: "periodStart",
                  minWidth: 50,
                  maxWidth: 125,
                  isResizable: true,
                },
                {
                  key: "v-end",
                  name: "Absence end",
                  fieldName: "periodEnd",
                  minWidth: 50,
                  maxWidth: 125,
                  isResizable: true,
                },
                {
                  key: "v-workdays",
                  name: "Workdays in period",
                  fieldName: "workdays",
                  minWidth: 50,
                  maxWidth: 100,
                  isResizable: true,
                },
                {
                  key: "v-requested-by",
                  name: "Requested by",
                  fieldName: "requestedBy",
                  minWidth: 100,
                  maxWidth: 200,
                  isResizable: true,
                },
                {
                  key: "v-approved-by",
                  name: "Approved by",
                  fieldName: "approvedBy",
                  minWidth: 100,
                  maxWidth: 200,
                  isResizable: true,
                },
                {
                  key: "v-comments",
                  name: "Comments",
                  fieldName: "comments",
                  minWidth: 100,
                  maxWidth: 150,
                  isResizable: true,
                },
                {
                  key: "v-status",
                  name: "Request status",
                  fieldName: "status",
                  minWidth: 50,
                  maxWidth: 100,
                  isResizable: true,
                },
                {
                  key: "v-type",
                  name: "Absence type",
                  fieldName: "type",
                  minWidth: 100,
                  maxWidth: 200,
                  isResizable: true,
                },
              ]}
              items={detailsListItems}
              groups={groups}
              groupProps={{
                showEmptyGroups: true,
                headerProps: {
                  onToggleCollapse: this._onGroupCollapseClicked,
                },
              }}
              onRenderItemColumn={(item, index, column) => {
                const fieldContent = item[column.fieldName];
                switch (column.key) {
                  case "v-start":
                    return <span>{fieldContent}</span>;
                  case "v-end":
                    return <span>{fieldContent}</span>;
                  case "v-status":
                    return (
                      <span>
                        {this._absencePeriodStatusToText(fieldContent)}
                      </span>
                    );
                  case "v-type":
                    return (
                      <span>{this._absencePeriodTypeToText(fieldContent)}</span>
                    );
                  case "v-approved-by":
                  case "v-requested-by":
                    return <User model={fieldContent} />;
                  case "v-comments":
                    return (
                      <DefaultButton
                        text="Open dialog"
                        onClick={() =>
                          this._onEditAbsencePeriodComment(item.absencePeriod)
                        }
                      />
                    );
                  case "v-resource":
                    return <Resource model={fieldContent} />;
                  default:
                    return <span>{fieldContent}</span>;
                }
              }}
              onSelect={this._onSelectAbsencePeriod}
              multiselect={false}
              isItemSelected={(item) =>
                apihelper.entityHasId(
                  item.absencePeriod,
                  selectedAbsencePeriodId
                )
              }
            />
          </div>
        ) : (
          <div className="novatime-container">
            <p>You are not eligible for requesting absence. </p>
            <p>If this is wrong, contact a partner to have this configured.</p>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let absencePeriodStatuses =
    absencePeriodStatusSelectors.allAbsencePeriodStatus(state);
  let absencePeriodTypes =
    absencePeriodTypeSelectors.allAbsencePeriodTypes(state);
  let currentUserAccount = reducerAccountSelectors.currentUserAccount(state);
  let currentUserResource = reducerResourceSelectors.currentUserResource(state);
  let locales = localeSelectors.allLocale(state);

  let selectedResourceId = pageSelectors.selectedResourceId(state);
  let selectedResource = pageSelectors.selectedResource(state);
  let userForSelectedResource = pageSelectors.userForSelectedResource(state);
  let vacationHoursGranted = pageSelectors.vacationHoursGranted(state);
  let selectedResourceLocaleName =
    pageSelectors.selectedResourceLocaleName(state);
  let allAbsencePeriodsForSelectedResource =
    pageSelectors.allAbsencePeriodsForCurrentFilter(state);
  let selectedResourceSpentVacation =
    pageSelectors.selectedResourceSpentVacation(state);
  let selectedResourceRemainingVacation =
    pageSelectors.selectedResourceRemainingVacation(state);
  let selectedAbsencePeriodId = pageSelectors.selectedAbsencePeriodId(state);
  let selectedAbsencePeriod = pageSelectors.selectedAbsencePeriod(state);
  let allResourcesForCurrentAccount =
    appDomainSelectors.allResourcesForSelectedAccount(state);
  let selectedResourceAbsencePeriodsGrouped =
    pageSelectors.selectedResourceAbsencePeriodsGrouped(state);
  let usersForAbsencePeriods = pageSelectors.usersForAbsencePeriods(state);
  let resourcesForAbsencePeriods =
    pageSelectors.resourcesForAbsencePeriods(state);
  let usersForCurrentAccount =
    appDomainSelectors.allUserForSelectedAccount(state);
  let pendingAbsencePeriodStatus =
    reducerAbsencePeriodStatusSelectors.absencePeriodStatusPending(state);
  let vacationAbsencePeriodType =
    reducerAbsencePeriodTypeSelectors.absencePeriodTypeVacation(state);
  const selectedYear = pageSelectors.selectedYear(state);
  const { startDate: vacationYearStartDate, endDate: vacationYearEndDate } =
    pageSelectors.vacationStartAndEndDate(state);

  return {
    isLoggedIn: isLoggedIn(state),
    currentUser: reducerUserSelectors.currentUser(state),
    selectedAbsencePeriod,
    selectedAbsencePeriodId,
    selectedResource,
    userForSelectedResource,
    currentUserResource,
    currentUserAccount,
    usersForCurrentAccount,
    selectedResourceId,
    allResourcesForCurrentAccount,
    vacationHoursGranted,
    selectedResourceLocaleName,
    selectedResourceSpentVacation,
    selectedResourceAbsencePeriodsGrouped,
    selectedResourceApprovedAbsencePeriod: pageSelectors.selectedResourceApprovedAbsencePeriod,
    allAbsencePeriodsForSelectedResource,
    usersForAbsencePeriods,
    resourcesForAbsencePeriods,
    selectedResourceRemainingVacation,
    locales: locales,
    pendingAbsencePeriodStatus,
    absencePeriodStatuses,
    absencePeriodTypes,
    vacationAbsencePeriodType,
    currentUserPermissionSet:
      permissionSelectors.currentUserPermissionSet(state),
    selectedYear,
    vacationYearStartDate,
    vacationYearEndDate,
  };
}

function mapDispatch(dispatch) {
  const actions = Object.assign({}, pageActions, editorActions);
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatch)(AbsencePeriods);
