import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { bindActionCreators } from "redux";
import {
  getRelId,
  getAttr,
  entityHasId,
  relHasId,
} from "../../selectors/apihelper";
import { CommandBar } from "@fluentui/react/lib/CommandBar";
import { Label } from "@fluentui/react/lib/Label";
import BasePickerSimple from "../../components/uifabricextensions/BasePickerSimple";
import DetailsListBasic from "../../components/lists/DetailsListBasic";
import * as icons from "../../constants/Icons";
import * as clientsAndContractsActions from "../../actions/pages/clientsAndContracts";
import * as authReducer from "../../selectors/reducers/auth";
import * as clientsAndContractsSelectors from "../../selectors/pages/clientsAndContracts";
import { createEmptyModel } from "../../lib/emptyModelCreation";
import * as EntityTypes from "../../constants/EntityTypes";
import * as currencySelectors from "../../selectors/reducers/currency";
import * as billabilitySelectors from "../../selectors/reducers/billabilityTypes";
import BillableSet from "../../lib/BillableSet";
import * as appDomainSelectors from "../../selectors/app/domain";
import * as editorActions from "../../actions/EntityEditor";
import { IconButton } from "@fluentui/react/lib/Button";
import { ContextualMenu } from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";
import ClientForm from "../../components/forms/ClientForm";
import ContractForm from "../../components/forms/ContractForm";
import ContractRoleForm from "../../components/forms/ContractRoleForm";
import DeleteForm from "../../components/forms/DeleteForm";

class ClientsAndContracts extends Component {
  constructor(props) {
    super(props);

    this._clientOnChange = this._clientOnChange.bind(this);
    this._onCreateClient = this._onCreateClient.bind(this);
    this._onEditClient = this._onEditClient.bind(this);
    this._onRemoveClient = this._onRemoveClient.bind(this);

    this._contractOnChange = this._contractOnChange.bind(this);
    this._onCreateContract = this._onCreateContract.bind(this);
    this._onEditContract = this._onEditContract.bind(this);
    this._onRemoveContract = this._onRemoveContract.bind(this);

    this._onCreateContractRole = this._onCreateContractRole.bind(this);
    this._onContractRoleSelect = this._onContractRoleSelect.bind(this);
    this._onContractRoleEdit = this._onContractRoleEdit.bind(this);
    this._onContractRoleRemove = this._onContractRoleRemove.bind(this);
  }

  componentDidMount() {
    this.props.actions.pageOpened();
  }

  // Client handlers
  _clientOnChange(element) {
    this.props.actions.selectClient(element);
  }

  _onCreateClient() {
    this.props.actions.startEdit(
      createEmptyModel(
        EntityTypes.CLIENT,
        {},
        {
          account: this.props.selectedAccount,
        }
      ),
      ClientForm
    );
  }

  _onEditClient() {
    this.props.actions.startEdit(this.props.selectedClient, ClientForm);
  }

  _onRemoveClient() {
    this.props.actions.startEdit(this.props.selectedClient, DeleteForm);
  }

  // Contract handlers
  _contractOnChange(element) {
    this.props.actions.selectContract(element);
  }

  _onCreateContract() {
    this.props.actions.startEdit(
      createEmptyModel(
        EntityTypes.CONTRACT,
        {},
        {
          client: this.props.selectedClient,
        }
      ),
      ContractForm
    );
  }

  _onEditContract() {
    this.props.actions.startEdit(this.props.selectedContract, ContractForm);
  }

  _onRemoveContract() {
    this.props.actions.startEdit(this.props.selectedContract, DeleteForm);
  }

  // Contract role handlers
  _onCreateContractRole() {
    this.props.actions.startEdit(
      createEmptyModel(
        EntityTypes.CONTRACTROLE,
        {
          effectiveDate: new Date(),
          expiryDate: moment().add(3, "months").toDate(),
        },
        {
          contract: this.props.selectedContract,
          currency: null,
        }
      ),
      ContractRoleForm
    );
  }

  _onContractRoleSelect(items) {
    this.props.actions.selectContractRole(
      items.length > 0 ? items[0].data : null
    );
  }

  _onContractRoleEdit() {
    this.props.actions.startEdit(
      this.props.selectedContractRole,
      ContractRoleForm
    );
  }

  _onContractRoleRemove() {
    this.props.actions.startEdit(this.props.selectedContractRole, DeleteForm);
  }

  render() {
    let {
      isLoggedIn,
      selectedAccount,
      selectedClient,
      selectedContract,
      clientsForSelectedAccount,
      contractsForSelectedClient,
      contractRolesForSelectedContract,
      allCurrency,
      billableSet,
    } = this.props;

    let getCurrencyAbbreviation = (contractRole) => {
      let curId = getRelId(contractRole, "currency");
      return curId
        ? allCurrency.find((c) => entityHasId(c, curId)).attributes.currencyCode
        : null;
    };

    let contractDetailListItems = selectedContract
      ? contractRolesForSelectedContract
          .map((cr) => {
            return {
              rate: getCurrencyAbbreviation(cr)
                ? (getAttr(cr, "hourPrice") || "0") +
                  " " +
                  getCurrencyAbbreviation(cr)
                : "",
              name: getAttr(cr, "name"),
              description: getAttr(cr, "description"),
              billable:
                getRelId(cr, "billabilityType") !== 0
                  ? relHasId(
                      cr,
                      "billabilityType",
                      billableSet.getIdForNotBillable()
                    )
                    ? "Yes"
                    : "No"
                  : "Undefined",
              effective: getAttr(cr, "effectiveDate")?.startsWith("0001")
                ? undefined
                : moment(getAttr(cr, "effectiveDate")).fromNow(),
              expire: getAttr(cr, "expiryDate")?.startsWith("0001")
                ? undefined
                : moment(getAttr(cr, "expiryDate")).fromNow(),
              data: cr,
            };
          })
          .sort(function (a, b) {
            return moment(getAttr(b.data, "expiryDate")).diff(
              moment(getAttr(a.data, "expiryDate"))
            );
          })
      : [];

    const ContractRolesMoreMenu = {
      items: [
        {
          key: "edit",
          text: "Edit",
          iconProps: { iconName: "Edit" },
          onClick: this._onContractRoleEdit,
        },
        {
          key: "remove",
          text: "Remove",
          iconProps: { iconName: "Remove" },
          onClick: this._onContractRoleRemove,
        },
      ],
    };

    const ClientMoreMenu = {
      items: [
        {
          key: "edit",
          text: "Edit",
          iconProps: { iconName: "Edit" },
          onClick: this._onEditClient,
        },
        {
          key: "remove",
          text: "Remove",
          iconProps: { iconName: "Remove" },
          onClick: this._onRemoveClient,
        },
      ],
    };

    const ContractMoreMenu = {
      items: [
        {
          key: "edit",
          text: "Edit",
          iconProps: { iconName: "Edit" },
          onClick: this._onEditContract,
        },
        {
          key: "remove",
          text: "Remove",
          iconProps: { iconName: "Remove" },
          onClick: this._onRemoveContract,
        },
      ],
    };

    return (
      <div className="novatime-container">
        <h2>Clients and contracts</h2>
        <CommandBar
          items={[
            {
              key: "new-entity",
              name: "New",
              iconProps: { iconName: icons.ICON_NAME_NEW },
              disabled: !isLoggedIn,
              subMenuProps: {
                items: [
                  {
                    key: "newClient",
                    name: "Client",
                    iconProps: { iconName: icons.ICON_NAME_CLIENT },
                    disabled: !selectedAccount,
                    onClick: this._onCreateClient,
                  },
                  {
                    key: "newContract",
                    name: "Contract",
                    iconProps: { iconName: icons.ICON_NAME_CONTRACT },
                    disabled: !selectedClient,
                    onClick: this._onCreateContract,
                  },
                  {
                    key: "newContractRole",
                    name: "Contract role",
                    iconProps: { iconName: icons.ICON_NAME_CONTRACTROLE },
                    onClick: this._onCreateContractRole,
                    disabled: !selectedContract,
                  },
                ],
              },
            },
          ]}
        />
        <br />
        <div style={{ maxWidth: "600px" }}>
          <Stack
            horizontal
            styles={{ root: { "justify-content": "space-between" } }}
          >
            <Stack.Item styles={{ root: { "min-width": "600px" } }}>
              <BasePickerSimple
                placeholder={selectedClient ? "" : "Select a client"}
                label="Client"
                autoComplete="on"
                selectedEntity={selectedClient}
                entities={clientsForSelectedAccount}
                disabled={!selectedAccount}
                onEntitySelected={this._clientOnChange}
              />
            </Stack.Item>
            <Stack.Item styles={{ root: { position: "relative" } }}>
              <IconButton
                styles={{ root: { position: "absolute", bottom: "0" } }}
                iconProps={{ iconName: icons.ICON_NAME_MORE }}
                menuProps={ClientMoreMenu}
                onRenderMenuIcon={() => <div />} // Disables the chevron
                menuAs={(props) => <ContextualMenu {...props} />}
                title={"Show actions"}
              />
            </Stack.Item>
          </Stack>

          <br />

          <Stack
            horizontal
            styles={{ root: { "justify-content": "space-between" } }}
          >
            <Stack.Item styles={{ root: { "min-width": "600px" } }}>
              <BasePickerSimple
                placeholder={selectedContract ? "" : "Select a contract"}
                label="Contract"
                autoComplete="on"
                selectedEntity={selectedContract}
                entities={contractsForSelectedClient}
                disabled={!selectedClient}
                onEntitySelected={this._contractOnChange}
              />
            </Stack.Item>
            <Stack.Item styles={{ root: { position: "relative" } }}>
              <IconButton
                styles={{ root: { position: "absolute", bottom: "0" } }}
                iconProps={{ iconName: icons.ICON_NAME_MORE }}
                menuProps={ContractMoreMenu}
                onRenderMenuIcon={() => <div />} // Disables the chevron
                menuAs={(props) => <ContextualMenu {...props} />}
                title={"Show actions"}
              />
            </Stack.Item>
          </Stack>
        </div>
        <br />
        <Label>Contract roles</Label>
        <DetailsListBasic
          columns={[
            {
              key: "cr-name",
              name: "Name",
              fieldName: "name",
              minWidth: 100,
              maxWidth: 200,
              isResizable: true,
            },
            {
              key: "cr-rate",
              name: "Hour rate",
              fieldName: "rate",
              minWidth: 100,
              maxWidth: 200,
              isResizable: true,
            },
            {
              key: "cr-billable",
              name: "Billable",
              fieldName: "billable",
              minWidth: 100,
              maxWidth: 100,
              isResizable: true,
            },
            {
              key: "cr-Description",
              name: "Description",
              fieldName: "description",
              minWidth: 100,
              maxWidth: 200,
              isResizable: true,
            },
            {
              key: "cr-effective",
              name: "Effective",
              fieldName: "effective",
              minWidth: 100,
              maxWidth: 200,
              isResizable: true,
            },
            {
              key: "cr-expire",
              name: "Expire",
              fieldName: "expire",
              minWidth: 100,
              maxWidth: 200,
              isResizable: true,
            },
          ]}
          items={contractDetailListItems}
          onRenderItemColumn={(item, index, column) => {
            const fieldContent = item[column.fieldName];
            switch (column.key) {
              case "cr-name":
                return (
                  <Stack
                    horizontal
                    styles={{ root: { "justify-content": "space-between" } }}
                  >
                    <Stack.Item
                      align="center"
                      shrink
                      styles={{ root: { overflow: "hidden" } }}
                    >
                      {fieldContent}
                    </Stack.Item>
                    <Stack.Item disableShrink>
                      <IconButton
                        iconProps={{ iconName: icons.ICON_NAME_MORE }}
                        menuProps={ContractRolesMoreMenu}
                        onRenderMenuIcon={() => <div />} // Disables the chevron
                        menuAs={(props) => <ContextualMenu {...props} />}
                        title={"Show actions"}
                      />
                    </Stack.Item>
                  </Stack>
                );
              default:
                return <span>{fieldContent}</span>;
            }
          }}
          onSelect={this._onContractRoleSelect}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let selectedAccount = appDomainSelectors.selectedAccount(state);
  let selectedClient = clientsAndContractsSelectors.selectedClientById(state);
  let selectedContract =
    clientsAndContractsSelectors.selectedContractById(state);
  let selectedContractRole =
    clientsAndContractsSelectors.selectedContractRoleById(state);

  let billableSet = new BillableSet(
    billabilitySelectors.allBillabilityTypes(state)
  );

  let clientsForSelectedAccount =
    appDomainSelectors.allClientsForSelectedAccount(state);
  let contractsForSelectedClient = selectedClient
    ? clientsAndContractsSelectors.allContractsBySelectedClientSorted(state)
    : [];
  let contractRolesForSelectedContract = selectedContract
    ? clientsAndContractsSelectors.allContractRolesBySelectedContract(state)
    : [];

  let isLoggedIn = authReducer.isLoggedIn(state);

  let allCurrency = currencySelectors.allCurrency(state);

  return {
    selectedAccount,
    selectedClient,
    selectedContract,
    isLoggedIn,
    clientsForSelectedAccount,
    contractsForSelectedClient,
    contractRolesForSelectedContract,
    allCurrency,
    selectedContractRole,
    billableSet,
  };
}

function mapDispatch(dispatch) {
  const actions = Object.assign({}, clientsAndContractsActions, editorActions);
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatch)(ClientsAndContracts);
