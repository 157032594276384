import {ResourceObject, ResourceObjects} from '../../lib/models';
import * as types from '../../constants/ActionTypes';

export function getAllTimeRegistrationSummaries({
                                                    resource = undefined,
                                                    resources = undefined,
                                                    startTime = undefined,
                                                    endTime = undefined }: {
                                                    resource?: ResourceObject,
                                                    resources?: ResourceObjects,
                                                    startTime?: Date | string,
                                                    endTime?: Date | string,
                                                } = {}, includes: string[] = []
) {
    if (!Array.isArray(resources)) {
        resources = [];
    }
    if (resource) {
        resources = resources.slice();
        resources.push(resource);
    }

    return {
        type: types.API_TIMEREGISTRATIONSUMMARY_GET_ALL_INIT,
        payload: {
            parameters: {
                resources,
                startTime: startTime instanceof Date ? startTime.toISOString() : startTime,
                endTime: endTime instanceof Date ? endTime.toISOString() : endTime
            },
            includes
        }
    };
}
