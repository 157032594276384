import { put, select, takeLatest } from "redux-saga/effects";
import * as types from "../../../constants/ActionTypes";
import { isLoggedIn } from "../../../selectors/reducers/auth";
import {
  endTimeFilter,
  selectedResource,
  startTimeFilter,
} from "../../../selectors/pages/timeRegistration";
import { getAllTimeRegistrations } from "../../../actions/api/timeRegistration";
import { getAllLockedTimePeriods } from "../../../actions/api/lockedTimePeriod";
import * as datehelper from "../../../lib/date";
import * as workLocationRegistrationActions from "../../../actions/api/workLocationRegistration";
import { ResourceObject } from "../../../lib/models";

function* doFetchWeekRegistrations() {
  const loggedIn = (yield select(isLoggedIn)) as boolean;
  const resource = (yield select(selectedResource)) as ResourceObject;
  let startTime = (yield select(startTimeFilter)) as Date;

  if (loggedIn && resource && startTime) {
    let endTime = (yield select(endTimeFilter)) as Date;
    if (!startTime) {
      startTime = datehelper.startOfWeek(new Date());
    }
    if (!endTime) {
      endTime = datehelper.endOfWeek(new Date());
    }
    const isCurrentWeek = datehelper.isBetween(new Date(), startTime, endTime);

    const possiblyEarlierStartTime = isCurrentWeek
      ? (startTime = datehelper.subtractDays(startTime, 7))
      : startTime;
    if (isCurrentWeek) {
      //Fetch from the past two weeks, so we know which projects to show in the table
      startTime = datehelper.subtractDays(startTime, 7);
    }

    yield put(
      workLocationRegistrationActions.getAllWorkLocationRegistrations({
        resources: [resource],
        startTime,
        endTime,
      })
    );
    yield put(
      getAllLockedTimePeriods({
        resource,
        startDate: possiblyEarlierStartTime,
        endDate: endTime,
      })
    );
    yield put(
      getAllTimeRegistrations(
        { resource, startTime: possiblyEarlierStartTime, endTime },
        ["project", "contractRole"]
      )
    );
  }
}

export default function* fetchWeekRegistrations() {
  yield takeLatest(
    types.APP_PAGE_TIMEREGISTRATION_FETCH_WEEK_REGISTRATIONS,
    doFetchWeekRegistrations
  );
}
