import moment from "moment";
import * as apihelper from "../selectors/apihelper";
import {ResourceIdentifier, ResourceObject} from "./models";

const validateAttribute =
  (attributeName: string, required = true, cls: any = null) =>
  (model: ResourceObject) => {
    if (!apihelper.hasAttr(model, attributeName) && required) {
      return "Mandatory attribute " + attributeName + " is missing\n";
    }
    const val = apihelper.getAttr(model, attributeName);
    if (
      cls &&
      apihelper.hasAttr(model, attributeName) &&
      !(typeof cls === "string" ? typeof val === cls : val instanceof cls)
    ) {
      const className =
        typeof val === "undefined"
          ? "undefined"
          : (val as object).constructor.name;
      return (
        "Attribute " + attributeName + " was class " + className + " not " + cls
      );
    }
    return "";
  };

// can either be Date or date string
const validateDateAttribute =
  (attributeName: string, required = true) =>
  (model: ResourceObject) => {
    const isDate = validateAttribute(attributeName, required, "string")(model);
    const isThereIfRequired = validateAttribute(attributeName, required)(model);
    const isNotRequiredOrValidDateStr = moment(
      apihelper.getAttr(model, attributeName) as string
    ).isValid();

    return isDate || (isThereIfRequired && isNotRequiredOrValidDateStr);
  };

const validateRelationship =
  (relationshipName: string) => (model: ResourceObject) =>
    !apihelper.relHasReference(model, relationshipName)
      ? "Mandatory relationship " + relationshipName + " is null\n"
      : "";

const validateDescriptionMatchesProjectSetting = (model: ResourceObject, included: Array<ResourceObject>) =>
    {
      const projectRelationShip = apihelper.getRel(model, "project")?.data as ResourceIdentifier;
      if (projectRelationShip) {
        const includedProject = included?.length && included
            .find((resource) => resource.type === projectRelationShip.type &&
                apihelper.getEntityId(resource) === projectRelationShip.id);
        const needsDescription = includedProject &&
            apihelper.getAttr(includedProject, "needsDescription");
        return (!!needsDescription && !apihelper.getAttr(model, "description")) &&
            "A description is required for this project";
      }
      return false;
    }

export type ValidationCollection = Record<string, (string | boolean)[]>;

export interface ValidationResult {
  validations: ValidationCollection;
  asString: string;
  isValid: boolean;
}

class Validation {
  private validations: Record<
    string,
    Array<(model: ResourceObject, included: Array<ResourceObject>) => string | boolean>
  >;

  constructor() {
    this.validations = {};
  }

  add(
    fieldName: string,
    validation: (model: ResourceObject, included: Array<ResourceObject>) => boolean | string
  ) {
    this.validations[fieldName] = this.validations[fieldName] || [];
    this.validations[fieldName].push(validation);
    return this;
  }

  addRelationship(fieldName: string) {
    return this.add(fieldName, validateRelationship(fieldName));
  }

  addAttribute(attributeName: string, required = true, cls: any = null) {
    return this.add(
      attributeName,
      validateAttribute(attributeName, required, cls)
    );
  }

  addDateAttribute(attributeName: string, required = true) {
    return this.add(
      attributeName,
      validateDateAttribute(attributeName, required)
    );
  }

  addDescriptionValidation() {
    return this.add("needs description", validateDescriptionMatchesProjectSetting)
  }

  validate(model: ResourceObject, included: Array<ResourceObject>): ValidationResult {
    const validationResults: Record<string, (string | boolean)[]> = {};
    Object.keys(this.validations).forEach((k) => {
      const vs = this.validations[k].map((v) => v(model, included)).filter((m) => !!m);
      if (vs.length > 0) {
        validationResults[k] = vs;
      }
    }, this);
    return {
      validations: validationResults,
      isValid: Object.keys(validationResults).length == 0,
      asString: Object.entries(validationResults)
        .map((pair) => pair[0] + " " + pair[1])
        .join("\n"),
    };
  }
}

interface EntityValidator {
  isValid(model: ResourceObject): boolean;
  validate(model: ResourceObject, included: Array<ResourceObject>): ValidationResult;
}

class TimeRegistrationValidator implements EntityValidator {
  private validation: Validation;

  constructor() {
    this.validation = new Validation()
      .addRelationship("resource")
      .addRelationship("project")
      .addRelationship("timeRegistrationStatus")
      .addAttribute("startTime", false, "string")
      .addDateAttribute("endTime", false)
      .addAttribute("description", false, "string")
      .addDescriptionValidation()
      .add("startTime", (model) =>
        new Date(apihelper.getAttr(model, "startTime") as string) <
        new Date(apihelper.getAttr(model, "endTime") as string)
          ? ""
          : "endTime is earlier than startTime"
      );
  }

  isValid(model: ResourceObject) {
    return this.validate(model, []).isValid;
  }

  validate(model: ResourceObject, included: Array<ResourceObject>) {
    return this.validation.validate(model, included);
  }
}

const validationMap: Record<string, EntityValidator> = {
  TimeRegistration: new TimeRegistrationValidator(),
};

export const isModelValid = (model: ResourceObject, included: Array<ResourceObject>) => {
  return validateModel(model, included).isValid;
};

export const validateModel = (model: ResourceObject, included: Array<ResourceObject>) => {
  if (typeof model.type !== "string") {
    throw "Cannot validate model. Model has no type (" + model.type + ")";
  }
  if (!validationMap[model.type]) {
    throw "Cannot validate model. No validator for type " + model.type;
  }
  return validationMap[model.type].validate(model, included);
};
