import { createSelector } from "reselect";
import * as userSelectors from './user';
import * as permissionTypeSelectors from './permissionTypes';
import * as storehelper from '../storehelper';
import * as contractSelectors from './contract';
import { UserPermissionSet } from '../../lib/PermissionSet';
import * as appDomainSelectors from '../app/domain';
import * as projectSelectors from './project';
import * as clientSelectors from './client';
import { ResourceObject, ResourceObjects } from "../../lib/models";

//allPermission from state tree

export const allPermissions = (state: any) => state.api.permission.allPermissions;

export const filterByUsers = (permissions: ResourceObjects, users: ResourceObject | ResourceObjects | null) =>
    storehelper.filterByReferenceToRelatedEntities(permissions, users, 'user');

export const currentUserPermissions = createSelector(
    [userSelectors.currentUser, allPermissions],
    (currentUser, permissions) => filterByUsers(permissions, currentUser)
);

export const currentUserPermissionSet = createSelector(
    [
        userSelectors.currentUser,
        currentUserPermissions,
        contractSelectors.allContracts,
        clientSelectors.allClients,
        projectSelectors.allProjects,
        permissionTypeSelectors.allPermissionTypes,
        appDomainSelectors.selectedAccount
    ],
    (currentUser, permissions, contracts, clients, projects, permissionTypes, selectedAccount) =>
        new UserPermissionSet(permissions, contracts, clients, projects, permissionTypes, currentUser as ResourceObject, selectedAccount as ResourceObject)
)
