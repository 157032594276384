import * as SagaHelpers from '../helpers';
import * as types from '../../constants/ActionTypes';
import * as apihelper from '../../selectors/apihelper';
import * as SagaEffects from 'redux-saga/effects';
import TimeRegistrationSummaryConnector from '../../connectors/TimeRegistrationSummary';

const getAllTimeRegistrationSummaries = SagaHelpers.createApiHandler(
    types.API_TIMEREGISTRATIONSUMMARY_GET_ALL_INIT,
    SagaHelpers.generateGetAllApiCall(
        TimeRegistrationSummaryConnector,
        function* (connector: TimeRegistrationSummaryConnector, action: any): any {
            const resources = action.payload.parameters.resources;
            const startTime = action.payload.parameters.startTime;
            const endTime = action.payload.parameters.endTime;
            const resourceIds = resources ? resources.map(apihelper.getEntityId) : null;

            return yield SagaEffects.call(
                [connector, connector.getAll],
                { resourceIds, startTime: new Date(startTime), endTime: new Date(endTime) },
                action.payload.includes);
        }
    )
)

export default function* timeRegistrationSummarySaga() {
    yield SagaEffects.fork(getAllTimeRegistrationSummaries);
}
