import { createSelector } from "reselect";
import * as accountSelectors from "../reducers/account";
import * as projectResourceSelectors from "../reducers/projectResource";
import * as reducerTimeRegistrationSelectors from "../reducers/timeRegistration";
import * as apihelper from "../apihelper";
import * as projectSelectors from "../reducers/project";
import * as contractRoleSelectors from "../reducers/contractRole";
import * as contractSelectors from "../reducers/contract";
import * as clientSelectors from "../reducers/client";
import * as writeBackQueueReducerSelectors from "../reducers/writeBackQueue";
import * as timeRegistrationSelectors from "../reducers/timeRegistration";
import * as storehelper from "../storehelper";
import * as resourceSelectors from "../reducers/resource";
import StateMerge from "../../lib/stateMerge/StateMerge";
import HourSet from "../../lib/HourSet";
import * as datehelper from "../../lib/date";
import * as lockedTimePeriodSelectors from "../reducers/lockedTimePeriod";
import * as apiCallReducerSelectors from "../reducers/apiCall";
import * as billabilityTypeSelectors from "../reducers/billabilityTypes";
import { NOT_BILLABLE } from "../../constants/BillabilityTypes";
import LockedTimePeriodIndex from "../../lib/LockedTimePeriodIndex";
import * as permissionSelectors from "../reducers/permission";
import * as reducerPermissionTypesSelectors from "../reducers/permissionTypes";
import * as notificationSelectors from "../app/notification";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import * as workLocationRegistrationSelectors from "../reducers/workLocationRegistration";
import * as userSelectors from "../reducers/user";
import {allProjects} from "../reducers/project";

const cmpByModelId = (a: ResourceObject, b: ResourceObject) => {
  const aId = apihelper.getEntityId(a);
  const bId = apihelper.getEntityId(b);
  const aHasId = !!aId;
  const bHasId = !!bId;
  if (!aHasId && !bHasId) {
    return 0;
  }
  if (aHasId && !bHasId) {
    return -1;
  }
  if (!aHasId && bHasId) {
    return 1;
  }
  return Number(bId) - Number(aId);
};

const cmpByStartTime = (a: ResourceObject, b: ResourceObject) =>
  new Date(apihelper.getAttr(a, "startTime") as string).getTime() -
  new Date(apihelper.getAttr(b, "startTime") as string).getTime();

export const sortTimeRegsByStartTime = (timeRegs: Array<ResourceObject>) =>
  timeRegs.slice().sort((a, b) => cmpByStartTime(a, b) || cmpByModelId(a, b));

export const selectedResource = (state: any) =>
  state.page.timeRegistration.filter.selectedResource;

export const selectedResources = createSelector(
  [selectedResource],
  (selectedResource) =>
    apihelper.isEntity(selectedResource) ? [selectedResource] : []
);

const filter = (state: any) => state.page.timeRegistration.filter;

export const startTimeFilter = createSelector([filter], (filter) =>
  filter.startTime !== null ? new Date(filter.startTime) : null
);

export const endTimeFilter = createSelector([filter], (filter) =>
  filter.endTime !== null ? new Date(filter.endTime) : null
);

type CurrentEdit = {
  timeRegistrationUnderEdit: ResourceObject;
};

export const currentEdit: (state: any) => CurrentEdit = (state) =>
  state.page.timeRegistration.currentEdit;

export const startTimeForTimeRegUnderEdit = createSelector(
  [currentEdit],
  (currentEdit) =>
    currentEdit && currentEdit.timeRegistrationUnderEdit
      ? new Date(
          apihelper.getAttr(
            currentEdit.timeRegistrationUnderEdit,
            "startTime"
          ) as string
        )
      : null
);

export const liveTimeRegistrations = (state: any) =>
  state.page.timeRegistration.liveTimeRegistrationRequests;

export const pendingNotifications = (state: any) =>
  state.page.timeRegistration.pendingNotifications;

export const savedProjectIds = (state: any) =>
  state.page.timeRegistration.savedProjectIds;

export const dialogHeader = (state: any) =>
  state.page.timeRegistration.dialogHeader;

export const dialogMessage = (state: any) =>
  state.page.timeRegistration.dialogMessage;

export const dialogCallback = (state: any) =>
  state.page.timeRegistration.dialogCallback;

export const lastWeekLockedCheckComplete = (state: any) =>
  state.page.timeRegistration.lastWeekLockedCheckComplete;

export const hasCurrentEdit = createSelector(
  [currentEdit],
  (currentEditVal) => !!currentEditVal.timeRegistrationUnderEdit
);

export const weekDatesForCurrentFilter = createSelector(
  [startTimeFilter],
  (startTime) =>
    startTime
      ? [0, 1, 2, 3, 4, 5, 6].map((offset) => {
          const d = new Date(startTime);
          d.setDate(d.getDate() + offset);
          return d;
        })
      : []
);

export const lockedTimePeriodIndexForSelectedResource = createSelector(
  [lockedTimePeriodSelectors.allLockedTimePeriods, selectedResource],
  (lockedTimePeriods, selectedResource) =>
    new LockedTimePeriodIndex(
      lockedTimePeriodSelectors.filterByResources(
        lockedTimePeriods,
        selectedResource
      )
    )
);

export const showCloseLastWeekNotification = createSelector(
  [lastWeekLockedCheckComplete, lockedTimePeriodIndexForSelectedResource],
  (checkComplete, lockedTimeIndex) => {
    return (
      checkComplete &&
      !lockedTimeIndex.isEntirePeriodLocked(
        datehelper.subtractDays(datehelper.startOfWeek(new Date()), 7),
        datehelper.subtractDays(datehelper.endOfWeek(new Date()), 7)
      )
    );
  }
);

export const lockedTimePeriodIndexForEntireWeek = createSelector(
  [startTimeFilter, endTimeFilter, lockedTimePeriodIndexForSelectedResource],
  (startTime, endTime, ltpIndex) =>
    ltpIndex.reduceByOpenDateRange(startTime, endTime)
);

export const weekForCurrentFilterCrossesMonth = createSelector(
  [startTimeFilter, endTimeFilter],
  (startTimeFilter, endTimeFilter) =>
    startTimeFilter !== null &&
    endTimeFilter !== null &&
    !datehelper.isInSameCalendarMonth(startTimeFilter, endTimeFilter)
);

export const endOfCurrentMonthCloseDate = createSelector(
  [startTimeFilter, weekForCurrentFilterCrossesMonth],
  (startTime, crossesMonth) =>
    crossesMonth ? datehelper.endOfMonth(startTime as Date) : null
);

export const lockedtimePeriodIndexForCurrentMonth = createSelector(
  [
    weekForCurrentFilterCrossesMonth,
    lockedTimePeriodIndexForEntireWeek,
    endOfCurrentMonthCloseDate,
    startTimeFilter,
  ],
  (crossesMonth, ltpIndex, endTime, startTime) =>
    crossesMonth ? ltpIndex.reduceByOpenDateRange(startTime, endTime) : null
);

export const startOfNextMonthPeriodStartDate = createSelector(
  [weekForCurrentFilterCrossesMonth, endTimeFilter],
  (crossesMonth, endTimeFilter) =>
    crossesMonth ? datehelper.startOfMonth(endTimeFilter as Date) : null
);

export const lockedTimePeriodIndexForNextMonth = createSelector(
  [
    weekForCurrentFilterCrossesMonth,
    lockedTimePeriodIndexForEntireWeek,
    startOfNextMonthPeriodStartDate,
    endTimeFilter,
  ],
  (crossesMonth, ltpIndex, startTime, endTime) =>
    crossesMonth ? ltpIndex.reduceByOpenDateRange(startTime, endTime) : null
);

export const closedDatesInWeekForCurrentFilter = createSelector(
  [
    weekDatesForCurrentFilter,
    lockedTimePeriodIndexForSelectedResource,
    selectedResource,
  ],
  (weekDates, ltpIndex, selectedResource) => {
    return weekDates.filter(
      (date) =>
        selectedResource &&
        ltpIndex.isDateLockedForResource(
          date,
          apihelper.getEntityId(selectedResource)
        )
    );
  }
);

export const isEntireCalendarWeekLocked = createSelector(
  [lockedTimePeriodIndexForEntireWeek, startTimeFilter, endTimeFilter],
  (ltpIndex, startDate, endDate) =>
    ltpIndex.isEntirePeriodLocked(startDate, endDate)
);

export const isNextMonthPartOfWeekPeriodClosed = createSelector(
  [
    weekForCurrentFilterCrossesMonth,
    startOfNextMonthPeriodStartDate,
    endTimeFilter,
    lockedTimePeriodIndexForNextMonth,
  ],
  (crossesMonth, startDate, endDate, ltprIndex) =>
    crossesMonth && ltprIndex !== null
      ? ltprIndex.isEntirePeriodLocked(startDate, endDate)
      : null
);

export const isCurrentMonthPartOfWeekPeriodClosed = createSelector(
  [
    weekForCurrentFilterCrossesMonth,
    startTimeFilter,
    endOfCurrentMonthCloseDate,
    lockedtimePeriodIndexForCurrentMonth,
  ],
  (crossesMonth, startDate, endDate, ltprIndex) =>
    crossesMonth && ltprIndex !== null
      ? ltprIndex.isEntirePeriodLocked(startDate, endDate)
      : null
);

export const isCurrentEditClosed = createSelector(
  [hasCurrentEdit, currentEdit, closedDatesInWeekForCurrentFilter],
  (hasCurrentEdit, currentEditVal, closedDates) => {
    if (hasCurrentEdit) {
      const curEditStartTime = new Date(
        apihelper.getAttr(
          currentEditVal.timeRegistrationUnderEdit,
          "startTime"
        ) as string
      );
      return (
        closedDates.filter((d) => datehelper.isSameDay(curEditStartTime, d))
          .length > 0
      );
    }
    return false;
  }
);

export const accountForSelectedResource = createSelector(
  [accountSelectors.allAccounts, selectedResource],
  (allAccounts, selectedResource) =>
    apihelper.isEntity(selectedResource)
      ? accountSelectors.filterAccountByResource(allAccounts, selectedResource)
      : null
);

export const projectsForSelectedResourceAccount = createSelector(
  [accountForSelectedResource, projectSelectors.allProjects],
  (account, projects) => projectSelectors.filterByAccounts(projects, account)
);

export const projectResourcesForSelectedResource = createSelector(
  [
    projectResourceSelectors.allProjectResources,
    selectedResource,
    projectsForSelectedResourceAccount,
  ],
  (
    allProjectResources,
    selectedResource,
    projectsForSelectedResourceAccount
  ) => {
    let result: ResourceObjects | null = null;
    if (apihelper.isEntity(selectedResource)) {
      // all project resources assigned to this specific resource
      result = projectResourceSelectors.filterByResource(
        allProjectResources,
        selectedResource
      );
      // all default project resources for the account the resource belongs to
      result = result.concat(
        projectResourceSelectors.filterByProjects(
          projectResourceSelectors.filterByIsDefaultProjectResource(
            allProjectResources
          ),
          projectsForSelectedResourceAccount
        )
      );
    }
    return result;
  }
);

export const projectsForSelectedResource = createSelector(
  [projectSelectors.allProjects, projectResourcesForSelectedResource],
  (allProjects, projectResources) =>
    projectResources !== null
      ? projectSelectors.filterByProjectResources(allProjects, projectResources)
      : null
);

export const timeRegistrationsForCurrentResourceAndPeriod = createSelector(
  [
    selectedResource,
    startTimeFilter,
    endTimeFilter,
    timeRegistrationSelectors.allTimeRegistrations,
  ],
  (selectedResource, startTime, endTime, allRegistrations) =>
    timeRegistrationSelectors.filterByResourcesAndPeriod(
      allRegistrations,
      selectedResource,
      startTime,
      endTime
    )
);

export const contractRolesForSelectedResource = createSelector(
  [
    contractRoleSelectors.allContractRoles,
    projectResourcesForSelectedResource,
    timeRegistrationsForCurrentResourceAndPeriod,
  ],
  (allContractRoles, projectResources, timeRegs) => {
    let result = contractRoleSelectors.filterByTimeRegistrations(
      allContractRoles,
      timeRegs
    );
    if (projectResources !== null) {
      const contractRolesByProjectResources =
        contractRoleSelectors.filterByProjectResources(
          allContractRoles,
          projectResources
        );
      result = storehelper.unionEntities(
        result,
        contractRolesByProjectResources
      );
    }
    return result;
  }
);

export const contractsForSelectedResource = createSelector(
  [contractSelectors.allContracts, contractRolesForSelectedResource],
  (allContracts, contractRoles) => {
    if (contractRoles !== null) {
      const contractsFromRoles = contractSelectors.filterByContractRoles(
        allContracts,
        contractRoles
      );
      return contractsFromRoles;
    } else {
      return null;
    }
  }
);

export const clientsForSelectedResurce = createSelector(
  [clientSelectors.allClients, contractsForSelectedResource],
  (allClients, contracts) =>
    contracts !== null
      ? clientSelectors.filterByContracts(allClients, contracts)
      : null
);

export const stateMergeForCurrentFilter = createSelector(
  [
    writeBackQueueReducerSelectors.queue,
    timeRegistrationsForCurrentResourceAndPeriod,
    apiCallReducerSelectors.liveTimeRegistrationWriteRequests,
  ],
  (queue, storeRegistrations, liveRegistrations) => {
    return new StateMerge(storeRegistrations, liveRegistrations, queue);
  }
);

export const projectForCurrentEdit = createSelector(
  [hasCurrentEdit, currentEdit, projectSelectors.allProjects],
  (hasCurrentEdit, currentEditVal, allProjects) =>
    hasCurrentEdit
      ? storehelper.findById(
          allProjects,
          apihelper.getRelId(
            currentEditVal.timeRegistrationUnderEdit,
            "project"
          ) as string
        )
      : null
);

// all project resources for the same project active on the same day as the current edit
export const activeProjectResourcesForCurrentEdit = createSelector(
  [
    hasCurrentEdit,
    currentEdit,
    projectResourceSelectors.allProjectResources,
    projectForCurrentEdit,
  ],
  (
    hasCurrentEdit,
    currentEditVal,
    allProjectResources,
    projectForCurrentEdit
  ) =>
    hasCurrentEdit
      ? projectResourceSelectors.filterByActiveAtDate(
          projectResourceSelectors.filterByProjects(
            allProjectResources,
            projectForCurrentEdit
          ),
          new Date(
            apihelper.getAttr(
              currentEditVal.timeRegistrationUnderEdit,
              "startTime"
            ) as string
          )
        )
      : null
);

export const activeProjectResourcesForCurrentEditForSelectedResource =
  createSelector(
    [
      hasCurrentEdit,
      activeProjectResourcesForCurrentEdit,
      projectResourcesForSelectedResource,
    ],
    (hasCurrentEdit, activePrs, selectedResourcePrs) =>
      hasCurrentEdit && activePrs !== null && selectedResourcePrs !== null
        ? storehelper.filterIntersectingEntities(activePrs, selectedResourcePrs)
        : null
  );

export const assignableResourcesForCurrentEdit = createSelector(
  [
    hasCurrentEdit,
    activeProjectResourcesForCurrentEdit,
    resourceSelectors.allResources,
    contractRoleSelectors.allContractRoles,
    contractSelectors.allContracts,
    clientSelectors.allClients,
    accountSelectors.allAccounts,
  ],
  (
    hasCurrentEdit,
    projectResources,
    allResources,
    allContractRoles,
    allContracts,
    allClients,
    allAccounts
  ) =>
    hasCurrentEdit
      ? resourceSelectors.filterAssignableResources(
          allResources,
          projectResources || [],
          allContractRoles,
          allContracts,
          allClients,
          allAccounts
        )
      : null
);

export const currentWeekRegistrations = createSelector(
  [stateMergeForCurrentFilter, startTimeFilter],
  (stateMerge, startTimeFilter) =>
    stateMerge.registrationsInWeek(startTimeFilter || new Date())
);

export const currentCellRegistrations = createSelector(
  [
    projectForCurrentEdit,
    stateMergeForCurrentFilter,
    selectedResource,
    startTimeForTimeRegUnderEdit,
    currentEdit,
    hasCurrentEdit,
  ],
  (
    project,
    stateMerge,
    selectedResource,
    date,
    currentEdit,
    hasCurrentEdit
  ) => {
    const registrations =
      project && date !== null
        ? stateMerge.registrationsByResourceAndProjectAndDay(
            selectedResource,
            project,
            date
          )
        : [];
    if (
      hasCurrentEdit &&
      !stateMerge.containsTimeRegistration(
        currentEdit.timeRegistrationUnderEdit
      )
    ) {
      registrations.push(currentEdit.timeRegistrationUnderEdit);
    }
    return sortTimeRegsByStartTime(registrations);
  }
);

export const hourSetForSelectedResource = createSelector(
  [
    stateMergeForCurrentFilter,
    allProjects,
    selectedResources,
    projectResourcesForSelectedResource,
    clientsForSelectedResurce,
    contractsForSelectedResource,
    contractRolesForSelectedResource,
  ],
  (
    stateMerge,
    projects,
    resources,
    projectResources,
    clients,
    contracts,
    contractRoles
  ) =>
    new HourSet(
      stateMerge,
      projects || [],
      resources,
      projectResources || [],
      clients || [],
      contracts || [],
      contractRoles || []
    )
);

export const isCurrentEditBeingDeleted = createSelector(
  [hasCurrentEdit, currentEdit, apiCallReducerSelectors.liveDeleteRequests],
  (hasCurrentEdit, currentEditVal, requests) =>
    hasCurrentEdit &&
    apiCallReducerSelectors.filterByModel(
      requests,
      currentEditVal.timeRegistrationUnderEdit
    ).length > 0
);

export const idForNotBillableType = createSelector(
  [billabilityTypeSelectors.allBillabilityTypes],
  (allBillabilityTypes) =>
    apihelper.getEntityId(
      billabilityTypeSelectors.findByName(allBillabilityTypes, NOT_BILLABLE)
    )
);

export const isCurrentEditBillable = createSelector(
  [
    hasCurrentEdit,
    currentEdit,
    billabilityTypeSelectors.allBillabilityTypes,
    contractRolesForSelectedResource,
  ],
  (hasCurrentEdit, currentEditVal, billabilityTypes, contractRoles) => {
    if (hasCurrentEdit && contractRoles !== null) {
      const idForNotBillableType = apihelper.getEntityId(
        billabilityTypeSelectors.findByName(billabilityTypes, NOT_BILLABLE)
      );
      const contractRoleId = apihelper.getRelId(
        currentEditVal.timeRegistrationUnderEdit,
        "contractRole"
      ) as string;
      const contractRole = storehelper.findById(contractRoles, contractRoleId);
      return (
        !!contractRole &&
        !apihelper.relHasId(
          contractRole,
          "billabilityType",
          idForNotBillableType
        )
      );
    } else {
      return null;
    }
  }
);

export const contractForCurrentEdit = createSelector(
  [hasCurrentEdit, projectForCurrentEdit, contractSelectors.allContracts],
  (hasCurrentEdit, projectForCurrentEdit, allContracts) =>
    hasCurrentEdit && projectForCurrentEdit
      ? contractSelectors.filterByProjects(
          allContracts,
          projectForCurrentEdit
        )[0]
      : null
);

export const clientForCurrentEdit = createSelector(
  [hasCurrentEdit, contractForCurrentEdit, clientSelectors.allClients],
  (hasCurrentEdit, contractForCurrentEdit, allClients) =>
    hasCurrentEdit && contractForCurrentEdit
      ? clientSelectors.filterByContracts(allClients, contractForCurrentEdit)[0]
      : null
);

export const registerableProjectsForCurrentEditClient = createSelector(
  [
    currentEdit,
    hasCurrentEdit,
    clientForCurrentEdit,
    projectsForSelectedResource,
    projectResourcesForSelectedResource,
    contractsForSelectedResource,
  ],
  (
    currentEdit,
    hasCurrentEdit,
    client,
    projects,
    projectResources,
    contracts
  ) => {
    if (
      hasCurrentEdit &&
      projects !== null &&
      projectResources !== null &&
      contracts &&
      client
    ) {
      const contractsAtClient = contractSelectors.filterByClients(
        contracts,
        client
      );
      const projectsForContracts = projectSelectors.filterByContracts(
        projects,
        contractsAtClient
      );
      const projectsForProjectResourcesAtClient =
        projectSelectors.filterByProjectResources(
          projectsForContracts,
          projectResources
        );
      return projectsForProjectResourcesAtClient;
    } else {
      return null;
    }
  }
);

export const isCurrentEditEditable = createSelector(
  [
    hasCurrentEdit,
    isCurrentEditClosed,
    isCurrentEditBeingDeleted,
    activeProjectResourcesForCurrentEditForSelectedResource,
    selectedResource,
    userSelectors.currentUser,
    permissionSelectors.currentUserPermissionSet,
    projectForCurrentEdit,
  ],
  (
    hasCurrentEdit,
    isCurrentEditClosed,
    isCurrentEditBeingDeleted,
    activeProjectResourcesForCurrentEditForSelectedResource,
    selectedResource,
    currentUser,
    currentUserPermissionSet,
    projectForCurrentEdit
  ) => {
    const hasWriteAccess =
      (currentUser !== null &&
        apihelper.relRefersToEntity(
          currentUser,
          "resource",
          selectedResource
        )) ||
      (projectForCurrentEdit &&
        currentUserPermissionSet.hasProjectWrite(projectForCurrentEdit));

    return (
      hasCurrentEdit &&
      !isCurrentEditClosed &&
      !isCurrentEditBeingDeleted &&
      activeProjectResourcesForCurrentEditForSelectedResource !== null &&
      activeProjectResourcesForCurrentEditForSelectedResource.length > 0 &&
      hasWriteAccess
    );
  }
);

export const isCurrentEditDeletable = createSelector(
  [
    hasCurrentEdit,
    currentEdit,
    isCurrentEditClosed,
    isCurrentEditBeingDeleted,
    permissionSelectors.currentUserPermissionSet,
    activeProjectResourcesForCurrentEditForSelectedResource,
  ],
  (
    hasCurrentEdit,
    currentEditVal,
    isCurrentEditClosed,
    isCurrentEditBeingDeleted,
    currentUserPermissionSet,
    activeProjectResourcesForCurrentEditForSelectedResource
  ) =>
    hasCurrentEdit &&
    apihelper.isPersistedEntity(currentEditVal.timeRegistrationUnderEdit) &&
    !isCurrentEditClosed &&
    !isCurrentEditBeingDeleted &&
    ((activeProjectResourcesForCurrentEditForSelectedResource !== null &&
      activeProjectResourcesForCurrentEditForSelectedResource.length > 0) ||
      currentUserPermissionSet.hasSelectedAccountWrite())
);

export const currentUserAccessLevelsForTimeReg = createSelector(
  [
    projectForCurrentEdit,
    clientForCurrentEdit,
    accountForSelectedResource,
    permissionSelectors.currentUserPermissions,
    reducerPermissionTypesSelectors.allPermissionTypes,
  ],
  (project, client, account, currentUserPermissions, permissionTypes) =>
    reducerTimeRegistrationSelectors.currentUserAccessLevelsForTimeReg(
      project,
      client,
      account,
      currentUserPermissions,
      permissionTypes
    )
);

export const isDirty = createSelector(
  [
    currentWeekRegistrations,
    writeBackQueueReducerSelectors.queue,
    projectSelectors.allProjects,
  ],
  (timeRegistrations, queue, allProjects) => {
    const timeRegistrationsAsList = (
      Array.isArray(timeRegistrations) ? timeRegistrations : [timeRegistrations]
    ).filter((r) => !!r);
    if (timeRegistrationsAsList.length === 0) {
      return false;
    }
    const grouped: Record<string, ResourceObjects> =
      timeRegistrationsAsList.reduce(
        (acc: Record<string, ResourceObjects>, tr) => {
          const projectId = apihelper.getRelId(tr, "project") as string;
          (acc[projectId] = acc[projectId] || []).push(tr);
          return acc;
        },
        {}
      );
    const tregRequireDescription = Object.keys(grouped).some((projectId) => {
      const project = allProjects.find((p) =>
        apihelper.entityHasId(p, projectId)
      );
      if (project && !apihelper.getAttr(project, "needsDescription")) {
        return false;
      }
      return grouped[projectId].some(
        (tr) => !apihelper.getAttr(tr, "description")
      );
    });
    const invalidQueue = timeRegistrations.filter((tr) => {
      const change = queue.findInQueue(tr);
      return change && change.status === "queue invalid";
    });

    return tregRequireDescription || invalidQueue.length > 0;
  }
);

export const queueIdForCurrentEdit = createSelector(
  [currentEdit, writeBackQueueReducerSelectors.queue],
  (currentEdit, queue) => {
    const change = queue.findInQueue(currentEdit.timeRegistrationUnderEdit);
    return change?.queueId;
  }
);

export const notificationsForCurrentEdit = createSelector(
  [queueIdForCurrentEdit, notificationSelectors.visibleNotifications],
  (queueId, notifications) =>
    notifications.filter(
      (notification) => queueId && notification?.location?.queueId === queueId
    )
);

export const weekWorkLocationRegistrations = createSelector(
  [
    workLocationRegistrationSelectors.allWorkLocationRegistrations,
    startTimeFilter,
    endTimeFilter,
    selectedResource,
  ],
  (allWorkLocationRegistrations, weekStart, weekEnd, selectedResource) =>
    weekStart !== null && weekEnd !== null
      ? workLocationRegistrationSelectors.filterByPeriod(
          storehelper.filterByReferenceToRelatedEntities(
            allWorkLocationRegistrations,
            selectedResource,
            "resource"
          ),
          weekStart,
          weekEnd
        )
      : []
);

export const workLocationRegistrationForCurrentEdit = createSelector(
  [hasCurrentEdit, weekWorkLocationRegistrations, startTimeForTimeRegUnderEdit],
  (hasCurrentEdit, workLocationRegistrations, startTimeForTimeRegUnderEdit) =>
    hasCurrentEdit
      ? workLocationRegistrationSelectors.filterByPeriod(
          workLocationRegistrations,
          datehelper.startOfDay(startTimeForTimeRegUnderEdit as Date),
          datehelper.endOfDay(startTimeForTimeRegUnderEdit as Date)
        )[0]
      : undefined
);
