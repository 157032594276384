import {put, select, takeLatest} from 'redux-saga/effects';
import * as types from '../../../constants/ActionTypes';
import {allProjectsByClient} from '../../../selectors/reducers/project';
import {getAllContracts} from '../../../actions/api/contract';
import * as projectsAndRolesSelectors from '../../../selectors/pages/projectsAndRoles';
import {selectProject} from '../../../actions/pages/projectsAndRoles';
import * as sagahelpers from '../../helpers';
import * as SagaEffects from 'redux-saga/effects';
import {currentUserPermissionSet} from '../../../selectors/reducers/permission';
import {ResourceObjects} from '../../../lib/models';

function* doClientSelected(action: any): any {
    if(action.payload !== null){
        yield sagahelpers.callAndAwait(getAllContracts(action.payload, 'projects'));
    }
    // select project if none selected
    const permissions = yield SagaEffects.select(currentUserPermissionSet)
    const projectSelected = yield select(projectsAndRolesSelectors.selectedProject)
    if (!projectSelected || !permissions.hasProjectRead(projectSelected)) {
        // dont take client from payload, take from store
        const clientId = action.payload;
        const projectsByClient: ResourceObjects = yield select(allProjectsByClient, clientId);
        const readableProject = projectsByClient.find(project => permissions.hasProjectRead(project))
        if (readableProject) {
            yield put(selectProject(readableProject));
        }
    }
}

export default function* clientSelected() {
    yield takeLatest(types.APP_PAGE_PROJECTS_AND_ROLES_CLIENT_SELECTED, doClientSelected);
}
