import {all, fork} from 'redux-saga/effects';
import pageOpened from './pageOpened';
import updateFilter from './updateFilter';
import loadRegistrations from './loadRegistrations';
import lockPeriods from './lockPeriods';
import openPeriods from './openPeriods';
import loadRegistrationSummaries from './loadRegistrationSummaries';

export default function* pageResourceManagementRootSaga() {
    yield all([
        fork(pageOpened),
        fork(updateFilter),
        fork(loadRegistrations),
        fork(lockPeriods),
        fork(openPeriods),
        fork(loadRegistrationSummaries),
    ]);
}
