import * as SagaEffects from "redux-saga/effects";
import * as types from "../../../constants/ActionTypes";
import * as timeRegistrationActionSelectors from "../../../selectors/actions/timeRegistration";
import * as writeBackQueueActions from "../../../actions/writeBackQueue";
import {getRelId} from '../../../selectors/apihelper';
import {ResourceObject} from '../../../lib/models';
import {projectById} from '../../../selectors/reducers/project';

function* doMakeChangeToModel(action: any) {
  const change = timeRegistrationActionSelectors.getChangeToModelChange(action);
  const model = timeRegistrationActionSelectors.getChangeToModelModel(action);
  const projectId = getRelId(model, "project");
  const included: Array<ResourceObject> = [];
  if (projectId) {
    const project = projectId && (yield (SagaEffects.select(projectById, projectId))) as ResourceObject;
    if (project) {
      included.push(project);
    }
  }
  yield SagaEffects.put(writeBackQueueActions.addChangeToQueue(change, model, included));
}

export default function* makeChangeToModel() {
  yield SagaEffects.takeLatest(
    types.APP_PAGE_TIMEREGISTRATION_MAKE_CHANGE_TO_MODEL,
    doMakeChangeToModel
  );
}
