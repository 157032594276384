import { createSelector } from "reselect";
import * as apihelper from '../apihelper';
import * as storehelper from "../storehelper";
import { isLoggedIn } from "../reducers/auth";
import * as accountSelectors from "../reducers/account";
import * as clientSelectors from "../reducers/client";
import * as projectSelectors from "../reducers/project";
import * as projectResourceSelectors from "../reducers/projectResource";
import * as resourceSelectors from "../reducers/resource";
import * as userSelectors from "../reducers/user";
import { lexicalInsensitiveNameSortFunc } from "../../lib/sorting";
import * as absenceRegistrationProjectSelectors from '../reducers/absenceRegistrationProject';
import * as absencePeriodTypeSelectors from '../reducers/absencePeriodType';
import * as appSettingsSelectors from './settings';
import { ResourceObject } from "../../lib/models";

export const selectedAccount = createSelector(
    [accountSelectors.allAccounts, appSettingsSelectors.selectedAccountId],
    (accounts, accountId) => accounts.find(a => apihelper.entityHasId(a, accountId)) as ResourceObject
);

export const allAbsenceRegistrationProjectsForSelectedAccount = createSelector(
    [selectedAccount, absenceRegistrationProjectSelectors.allAbsenceRegistrationProjects],
    (account, absenceRegistrationProjects) => 
        storehelper.filterByReferenceToRelatedEntities(absenceRegistrationProjects, account, "account")
);

export const allProjectsForSelectedAccount = createSelector(
    [selectedAccount, projectSelectors.allProjects],
    (account, projects) => projectSelectors.filterByAccounts(projects, account)
);

export const allProjectResourcesForSelectedAccount = createSelector(
    [allProjectsForSelectedAccount, projectResourceSelectors.allProjectResources],
    (projects, projectResources) => projectResourceSelectors.filterByProjects(projectResources, projects)
);

export const allUserForSelectedAccount = createSelector(
    [selectedAccount, userSelectors.allUsers],
    (account, users) => userSelectors.filterByAccount(users, account)
);

export const usersForCurrentAccount = createSelector(
    [isLoggedIn, allUserForSelectedAccount],
    (isLoggedIn, allUserForSelectedAccount) => {
        if (isLoggedIn) {return allUserForSelectedAccount}
        else {return []}
    }
);

export const allClientsForSelectedAccount = createSelector(
    [selectedAccount, clientSelectors.allClients],
    (selectedAccount, allClients) => storehelper.filterByReferenceToRelatedEntities(allClients, selectedAccount, "account").flatten().sort(lexicalInsensitiveNameSortFunc)
);

export const allResourcesForSelectedAccount = createSelector(
    [resourceSelectors.allResources, selectedAccount],
    (allResources, selectedAccount) => {
        return storehelper.filterByReferenceToRelatedEntities(allResources, selectedAccount, 'account').flatten();
    }
);

export const selectedAccountVacationProjectId = createSelector(
    [
        allAbsenceRegistrationProjectsForSelectedAccount, 
        absencePeriodTypeSelectors.absencePeriodTypeVacation,
    ],
    (absenceRegistrationProjects, absencePeriodTypeVacation) => {
        const registeredVacationProjects = storehelper.filterByReferenceToRelatedEntities(absenceRegistrationProjects, absencePeriodTypeVacation, 'absencePeriodType');
        return registeredVacationProjects.length > 0 ? apihelper.getRelId(registeredVacationProjects[0], 'project') as string : null;
    }
)

export const selectedAccountVacationProject = createSelector(
    [
        selectedAccountVacationProjectId,
        projectSelectors.allProjects
    ],
    (vacationProjectId, projects) => 
        vacationProjectId !== null ? storehelper.findById(projects, vacationProjectId) : null 
)
