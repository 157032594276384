import * as permissionTypesSelectors from "../../selectors/reducers/permissionTypes";
import * as reducerResourceSelectors from "../../selectors/reducers/resource";
import { createSelector } from "reselect";
import * as reducerPermissionSelectors from "../../selectors/reducers/permission";
import * as reducerUserSelectors from "../../selectors/reducers/user";
import * as reducerClientSelectors from "../../selectors/reducers/client";
import * as reducerProjectSelectors from "../../selectors/reducers/project";
import * as reducerAccountSelectors from "../../selectors/reducers/account";
import * as apihelper from "../../selectors/apihelper";
import * as appDomainSelectors from "../app/domain"
import * as reducerContractSelectors from "../../selectors/reducers/contract";
import * as storehelper from '../storehelper';

export const selectedUserId = state => state.page.usersAndResources.selectedUserId;

export const selectedResourceId = state => state.page.usersAndResources.selectedResourceId;

export const selectedPermissionUserId = state => state.page.usersAndResources.selectedPermissionUserId;
export const selectedPermissionAccountId = state => state.page.usersAndResources.selectedPermissionAccountId;
export const selectedPermissionClientId = state => state.page.usersAndResources.selectedPermissionClientId;
export const selectedPermissionProjectId = state => state.page.usersAndResources.selectedPermissionProjectId;


export const toggleInactives = state => state.page.usersAndResources.toggleInactives; 

export const allResourcesForSelectedAccount = createSelector(
    [appDomainSelectors.selectedAccount, reducerResourceSelectors.allResources], 
    (selectedAccount, resources) => resources.filter(c => apihelper.relRefersToEntity(c, 'account', selectedAccount))
)

export const selectedPermissionUserPermissions = createSelector(
    [reducerPermissionSelectors.allPermissions, selectedPermissionUserId],
    (permissions, userId) => permissions.filter(p => apihelper.relHasId(p, "user", userId))
)

export const allUsersForSelectedAccount = createSelector(
    [appDomainSelectors.selectedAccount, reducerUserSelectors.allUsers],
    (selectedAccount, users) => users.filter(user => apihelper.relRefersToEntity(user, 'account', selectedAccount))
)

export const userBySelectedId = createSelector(
    [selectedUserId, reducerUserSelectors.allUsers],
    (userId, allUsers) => allUsers.find(u => apihelper.entityHasId(u, userId))
)

export const selectedPermissionUser = createSelector(
    [selectedPermissionUserId, reducerUserSelectors.allUsers],
    (userId, allUsers) => allUsers.find(u => apihelper.entityHasId(u, userId))
)

export const selectedResource = createSelector(
    [reducerResourceSelectors.allResources,selectedResourceId],
    (allResources, resourceId) => allResources.find(r => apihelper.entityHasId(r, resourceId))
)

export const permissionTypeNameToIds = createSelector(
    [permissionTypesSelectors.allPermissionTypes],
    (allPermissionTypes) =>  allPermissionTypes.reduce((acc, cur) => { 
        acc[apihelper.getAttr(cur, "name")] = apihelper.getEntityId(cur);
        return acc;
    }, {})
)

export const allClientsForSelectedAccount = createSelector (
    [appDomainSelectors.selectedAccount, reducerClientSelectors.allClients],
    (selectedAccount, allClients) => allClients.filter(c => apihelper.relRefersToEntity(c, 'account', selectedAccount))
)

export const allClientsForPermissions = createSelector(
    [reducerPermissionSelectors.allPermissions, permissionTypeNameToIds, reducerClientSelectors.allClients],
    (allPermissions, permissionTypeNameToIds, allClients) => allPermissions.filter(p => [permissionTypeNameToIds.ClientWrite, permissionTypeNameToIds.ClientRead].includes(apihelper.getRelId(p, "permissionType")))
    .map(p => apihelper.getRelId(p, "client"))
    .unique()
    .map(clientId => allClients.find(c => apihelper.entityHasId(c, clientId)))
    .filter(c => apihelper.isEntity(c))
)
export const allProjectsForSelectedAccount = createSelector(
    [appDomainSelectors.selectedAccount,reducerProjectSelectors.allProjects],
    (selectedAccount, allProjects) => allProjects.filter(p => apihelper.relRefersToEntity(p, 'account', selectedAccount))
)

export const allProjectsForPermissions = createSelector(
    [reducerPermissionSelectors.allPermissions, permissionTypeNameToIds, reducerProjectSelectors.allProjects],
    (allPermissions, permissionTypeNameToIds, allProjects ) => allPermissions.filter(p => [permissionTypeNameToIds.ProjectWrite, permissionTypeNameToIds.ProjectRead].includes(apihelper.getRelId(p, "permissionType")))
    .map(p => apihelper.getRelId(p, "project"))
    .unique()
    .map(id => allProjects.find(p => apihelper.entityHasId(p, id)))
    .filter(p => apihelper.isEntity(p))
)

export const allPermissionsWithParents = createSelector(
    [reducerPermissionSelectors.allPermissions, 
        reducerAccountSelectors.allAccounts, 
        reducerContractSelectors.allContracts, 
        reducerClientSelectors.allClients, 
        allProjectsForPermissions, 
        permissionTypeNameToIds, 
        allUsersForSelectedAccount],
    (permissions, allAccounts, allContracts, allClients, allProjectsForPermissions, permissionTypeNameToIds, allUsersForSelectedAccount) => {
        
        let allPermissionsWithParentsList = [];
        for (const permission of permissions) {
            let userId = apihelper.getRelId(permission, "user");
            let permissionTypeId = apihelper.getRelId(permission, "permissionType");

            // Don't consider GlobalAdmin or GlobalAccountant permissions
            if (permissionTypeId == permissionTypeNameToIds.GlobalAdmin || permissionTypeId == permissionTypeNameToIds.GlobalAccountant) {
                continue;
            }

            let user = allUsersForSelectedAccount.find(u => apihelper.entityHasId(u, userId));
            let projectId, clientId, accountId, account, client, project, contract;
            switch (permissionTypeId) {
                // Note: Use of fall through
                case permissionTypeNameToIds.ProjectRead:
                case permissionTypeNameToIds.ProjectWrite:
                    projectId   = apihelper.getRelId(permission, 'project');
                    project     = allProjectsForPermissions.find(a => apihelper.entityHasId(a, projectId));
                    contract    = allContracts.find(c => apihelper.entityHasId(c, apihelper.getRelId(project, 'contract')));
                    clientId    = apihelper.getRelId(contract, 'client');
                case permissionTypeNameToIds.ClientRead:
                case permissionTypeNameToIds.ClientWrite:
                    clientId    = clientId ? clientId : apihelper.getRelId(permission, 'client');
                    client      = allClients.find(a => apihelper.entityHasId(a, clientId));
                    accountId   = apihelper.getRelId(client, 'account')
                case permissionTypeNameToIds.AccountRead:
                case permissionTypeNameToIds.AccountWrite:
                    accountId   = accountId ? accountId : apihelper.getRelId(permission, 'account');
                    account     = allAccounts.find(a => apihelper.entityHasId(a, accountId));
            }

            allPermissionsWithParentsList.push({user, account, client, project, permissionTypeId});
        }  
        return allPermissionsWithParentsList;
    }
)

export const clientByProjectIdMap = createSelector(
    [reducerProjectSelectors.allProjects, reducerClientSelectors.allClients, reducerContractSelectors.allContracts],
    (allProjects, allClients, allContracts) => allProjects
        .map(project => ({
            projectId: apihelper.getEntityId(project), 
            client: storehelper.filterByRelatedEntity(allContracts, project, 'contract').map(contract => storehelper.filterByRelatedEntity(allClients, contract, 'client')[0])[0]
        }))
)


