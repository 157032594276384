import {createSelector} from "reselect";
import * as apihelper from "../apihelper";
import * as AbsencePeriodTypesName from '../../constants/AbsencePeriodTypeNames';
import { ResourceObjects } from "../../lib/models";

export const allAbsencePeriodTypes = (state: any) => state.api.absencePeriodType.allAbsencePeriodType as ResourceObjects;

export const filterByName = (absencePeriodTypes: ResourceObjects, absencePeriodTypeName: string) =>
absencePeriodTypes.find(abt => apihelper.getAttr(abt, 'name') === absencePeriodTypeName);

export const absencePeriodTypeVacation = createSelector(
    [allAbsencePeriodTypes],
    (absencePeriodTypes) => filterByName(absencePeriodTypes, AbsencePeriodTypesName.VACATION.name)
)
