import {put, select} from 'redux-saga/effects';
import moment from 'moment';
import * as types from '../../../constants/ActionTypes';
import {takeLatestAfterBootstrap} from '../../helpers';
import {getAllClients} from "../../../actions/api/client";
import {getAllResources} from "../../../actions/api/resource";
import {selectMonth} from '../../../actions/pages/resourceManagement';
import {isAllClientsSelected, startTime} from '../../../selectors/pages/resourceManagement';
import {currentUserAccount} from "../../../selectors/reducers/account";
import {isLoggedIn} from '../../../selectors/reducers/auth';
import {getAllProjectsForPermission} from "../../../actions/api/project";
import {getAllProjectResources} from '../../../actions/api/projectResource';
import {getAllContractRoles} from '../../../actions/api/contractRole';
import * as absencePeriodActions from '../../../actions/api/absencePeriod';

function* doResourceManagementPageOpened() {
    const loggedIn = yield select(isLoggedIn);

    if (loggedIn) {
        const periodStart = moment().add(-1, "m");
        yield put(absencePeriodActions.getAllAbsencePeriods(periodStart.toDate()));
        let st = yield select(startTime);

        if(st == null){
            yield put(selectMonth(Date.now()))
        }

        yield put({ type: types.APP_PAGE_RESOURCEMANAGEMENT_LOAD_REGISTRATIONS });
        yield put({ type: types.APP_PAGE_RESOURCEMANAGEMENT_LOAD_REGISTRATIONSUMMARIES });

        let allClientsSelected = yield select(isAllClientsSelected);

        // this could be the first time the page is shown, ensure the proper data is loaded
        if(allClientsSelected){
            let curAcct = yield select(currentUserAccount);
            yield put(getAllResources(curAcct));
            yield put(getAllClients(curAcct, 'contracts'));
            yield put(getAllProjectsForPermission(curAcct));
            yield put(getAllProjectResources());
            yield put(getAllContractRoles());
        }
    }
}

export default function* resourceManagementPageOpened() {
    yield takeLatestAfterBootstrap(types.APP_PAGE_RESOURCEMANAGEMENT_OPENED, doResourceManagementPageOpened);
}
