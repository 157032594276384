import { getAttr, getEntityId } from '../apihelper';
import { ALL_BILLABLE, NOT_BILLABLE } from '../../constants/BillabilityTypes';
import BillableSet from '../../lib/BillableSet';
import { createSelector } from 'reselect';

export const findByName = (billabilityTypes, name) => billabilityTypes.find(bt => getAttr(bt, 'name') === name);

export const allBillabilityTypes = state => state.api.billabilitytypes.allBillabilityTypes;

export const billabilityTypesByName = (state, name) => findByName(allBillabilityTypes(state), name);

export const billableBillabilityTypes = state => ALL_BILLABLE.map(bName => billabilityTypesByName(state, bName));

export const subcontracterBillabilityTypes = state => [billabilityTypesByName(state, 'External'), billabilityTypesByName(state,'Broker')];

export const isBillabilityTypeBillable = (billabilityType) => BillableSet.isEntityBillable(billabilityType);

export const findByBillabilityTypeName = (billabilityTypes, name) => billabilityTypes.find(bt => getAttr(bt, 'name') === name);

export const idForNotBillableType = createSelector(
    [allBillabilityTypes],
    (billabilityTypes) => getEntityId(findByBillabilityTypeName(billabilityTypes, NOT_BILLABLE))
)
