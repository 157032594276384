import * as types from '../constants/ActionTypes';

export function addChangeToQueue(changeToModel, model, included){
    return {
        type: types.WRITEBACK_QUEUE_ADD_CHANGE_TO_QUEUE_INIT,
        payload: {
            change: changeToModel,
            model,
            included
        }
    }
}

export function claimModelForWriteBack(model, claimIdentifier){
    return {
        type: types.WRITEBACK_QUEUE_CLAIM_MODEL_FOR_WRITEBACK_INIT,
        payload: { model, claimIdentifier }
    }
}

export function initiateWriteback(claimIdentifier, changes) {
    return {
        type: types.WRITEBACK_QUEUE_WRITEBACK_INIT,
        payload: { changes, claimIdentifier }
    };
}

export function writebackStarted(claimId, model) {
    return {
        type: types.WRITEBACK_QUEUE_WRITEBACK_STARTED,
        payload: { claimId, model }
    };
}

export function writebackComplete(claimIdentifier, oldMergedModel, newMergedModel, storeModel, success) {
    return {
        type: types.WRITEBACK_QUEUE_WRITEBACK_COMPLETE,
        payload: { claimIdentifier, oldMergedModel, newMergedModel, storeModel, success }
    };
}

export function rollbackAllChanges(){
    return {
        type: types.WRITEBACK_QUEUE_ROLLBACK_ALL_QUEUED_CHANGES
    }
}

export function rollbackAllInvalidChanges() {
    return {
        type: types.WRITEBACK_QUEUE_ROLLBACK_ALL_QUEUED_INVALID_CHANGES
    }
}

export function writeBackSuccess(claimIdentifier, model, sentModel) {
    return {
        type: types.WRITEBACK_QUEUE_WRITEBACK_SUCCESS,
        payload: { claimIdentifier, model, sentModel }
    };
}

export function writeBackFailed(claimIdentifier, error) {
    return {
        type: types.WRITEBACK_QUEUE_WRITEBACK_ERROR,
        payload: { claimIdentifier, error }
    };
}
